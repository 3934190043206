import React, { useContext } from 'react';
import '../../logout/logout.css';
import Header from '../../logout/header/header';
import Footer from '../../logout/footer/footer';
import PersonProfile from '../../../components/profile/person_profile';
import CompanyProfile from '../../../components/profile/company_profile';
import AuthContext from '../../../contexts/AuthContext';

const Profile = () => {
  const { userNode } = useContext(AuthContext);  
  
  return (
    <>
    <Header />
      <div>
        { (userNode?.registertypeof === "person") ?
          <PersonProfile/>
        : (userNode?.registertypeof === "company") ?
          <CompanyProfile/>
        :
          null  
        }
      </div>
    <Footer />
    </>
  );
};
export default Profile;