import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

import Button from '../../../../components/button/button';
import TextBox from '../../../../components/textbox/textbox';
import AddCompanyContext from '../../../../contexts/AddCompanyContext';
import { API_ROOT } from '../../../../utils/constants';
import useGarages from '../../../../hooks/useGarages';
import '../../../../styles/multiple_inputs.css';


const AddCompanyDetails: React.FC<{ registrationType: string }> = ({ registrationType }) => {
    const [loading, setLoading] = useState(false);
    const { state, dispatch } = useContext(AddCompanyContext);
    const garages = useGarages().filter((g) => g.available);
    const [selectedGarages, setSelectedGarages] = useState<string[]>();
    const navigate = useNavigate();

    const regCompany = async () => {
        setLoading(true)
        await axios.post(`${API_ROOT}/create-company`, {
            companyName: state.companyName,
            address: state.address,
            registrationNumber: state.registrationNumber,
            email: state.email,
            password: state.password,
            contactPhones: state.contactPhones,
            plateNumbers: state.plateNumbers,
            startOfRental: state.startOfRental,
            billingFrequency: state.billingFrequency,
            registertypeof: registrationType,
            registryOrganization: state.registryOrganization,
            statisticalNumber: state.statisticalNumber,
            taxNumber: state.taxNumber,
            name: state.representativeName,
            selectedGarages,
        });
        alert('Cég regisztrálva lett!')
        setLoading(false);
        navigate('/admin/users');
    };

    return (
        <>
            <label>Cégnév:</label>
            <TextBox value={state.companyName} onChange={(e) => dispatch({ companyName: e })} name="CompanyName" />
            <label>Székhely:</label>
            <TextBox value={state.address} onChange={(e) => dispatch({ address: e })} name="CompanyAddress" placeholder="1037 Budapest, Teszt utca 4" />
            <label>Cégjegyzék száma:</label>
            <TextBox value={state.registrationNumber} onChange={(e) => dispatch({ registrationNumber: e })} name="CompanyRegistrationNumber" />
            <label>Nyilvántartó szervezete:</label>
            <TextBox value={state.registryOrganization} onChange={(e) => dispatch({ registryOrganization: e })} name="CompanyRegistryOrganization" />
            <label>Statisztikai számjele:</label>
            <TextBox value={state.statisticalNumber} onChange={(e) => dispatch({ statisticalNumber: e })} name="CompanyStatisticalNumber" />
            <label>Adószáma:</label>
            <TextBox value={state.taxNumber} onChange={(e) => dispatch({ taxNumber: e })} name="CompanyTaxNumber" />
            <label>Képviselő neve:</label>
            <TextBox value={state.representativeName} onChange={(e) => dispatch({ representativeName: e })} name="CompanyRepresentativeName" />
            <div className='multipleInputContainer'>
                <label>Kapcsolattartó Telefonszám:</label>
                <div className="multipleInputButtonsContainer">
                    <button disabled={state.contactPhones.length <= 1} onClick={() => {
                        const temp = [...state.contactPhones]
                        temp.pop();
                        dispatch({ contactPhones: temp })
                    }}
                    >-</button>
                    <br />
                    <button disabled={state.contactPhones.length >= 3} onClick={() => dispatch({ contactPhones: [...state.contactPhones, ''] })}>+</button>
                </div>
            </div>
            {state.contactPhones.map((number, i) => (
                <TextBox key={i} value={number}
                    onChange={(e) => {
                        const temp = [...state.contactPhones];
                        temp[i] = e;
                        dispatch({ contactPhones: temp })
                    }}
                    name="CompanyContactPhone"
                    placeholder="+36301111232"
                />
            ))}
            <div className='multipleInputContainer'>
                <label>Rendszám:</label>
                <div className="multipleInputButtonsContainer">
                    <button disabled={state.plateNumbers.length <= 1} onClick={() => {
                        const temp = [...state.plateNumbers]
                        temp.pop();
                        dispatch({ plateNumbers: temp })
                    }}
                    >-</button>
                    <br />
                    <button disabled={state.plateNumbers.length >= 3} onClick={() => dispatch({ plateNumbers: [...state.plateNumbers, ''] })}>+</button>
                </div>
            </div>
            {state.plateNumbers.map((number, i) => (
                <TextBox key={i} value={number}
                    onChange={(e) => {
                        const temp = [...state.plateNumbers];
                        temp[i] = e;
                        dispatch({ plateNumbers: temp })
                    }}
                    name="CompanyPlateNumber"
                    placeholder="LDX - 234, AA - AA - 123"
                />
            ))}
            <label> E-mail cím:</label>
            <TextBox type="email" value={state.email} onChange={(e) => dispatch({ email: e })} name="CompanyEmail" placeholder="tesztelek@gmail.com" />
            <label>Jelszó:</label>
            <TextBox value={state.password} onChange={(e) => dispatch({ password: e })} name="CompanyPwd" placeholder="Minimum 8 karakter kis és nagybetű, illetve szám" />
            <label>Beállók száma:</label>
            <br />
            <Select isMulti options={garages.map(({ name, id }) => ({ label: name, value: id }))} onChange={(v) => setSelectedGarages(v.map(({ value }) => value))} />
            <br />
            <br />
            <label>Bérlés kezdete:</label>
            <TextBox type="date" value={state.startOfRental} onChange={(e) => dispatch({ startOfRental: e })} name="StartOfRental" />
            <label>Számlázási gyakoriság:</label>
            <br />
            <select value={state.billingFrequency} onChange={(e) => dispatch({ billingFrequency: e.target.value })}>
                <option value="MONTHLY">Havonta</option>
                <option value="QUARTERLY">Negyed Évente</option>
                <option value="HALFLY">Fél Évente</option>
                <option value="YEARLY">Évente</option>
            </select>
            <br />
            <br />
            <Button className="adatCancel" onClick={() => navigate('/admin')}>Mégse</Button>
            <Button disabled={
                loading || !registrationType || !state.companyName || !state.address ||
                !state.taxNumber || !state.contactPhones[0].length || !state.plateNumbers[0].length ||
                !state.email || !state.password || !selectedGarages?.length || !state.startOfRental ||
                !state.statisticalNumber || !state.registrationNumber
            } className="adatTovabb" onClick={regCompany}>Új ügyfél felvétel</Button>
        </>
    );
}

export default AddCompanyDetails;