import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';

import Button from "../../../../components/button/button";
import TextBox from "../../../../components/textbox/textbox";
import AddUserPersonalContext from "../../../../contexts/AddUserPersonalContext";
import { API_ROOT } from "../../../../utils/constants";
import useGarages from "../../../../hooks/useGarages";
import { IUser } from "../../../../hooks/useFirebaseAuthentication";
import useReservations from "../../../../hooks/useReservations";

interface Props {
  user: IUser;
  refetch: () => Promise<any>;
}

const EditPersonalDetails: React.FC<Props> = ({ user, refetch }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const garages = useGarages();
  const reservations = useReservations();
  const [submitting, setSubmitting] = useState(false);
  const { state, dispatch } = useContext(AddUserPersonalContext);
  const [selectedGarages, setSelectedGarages] = useState<string[]>();
  const userReservations = reservations.filter((r) => r.userId === id).map((r) => r.garageId);
  const userGarages = garages.filter((g) => userReservations.includes(g.id));
  const availableGarages = garages.filter((g) => g.available);
  
  useEffect(() => {
    setSelectedGarages(userReservations);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, userReservations.length]);
  

  useEffect(() => {
    if (user) {
      dispatch({
        personalAddress: user.address,
        billingFrequency: user.billingFrequency || state.billingFrequency,
        email: user.email,
        personalId: user.id_card,
        personalName: user.name,
        contactPhones: user.contactPhones,
        plateNumbers: user.plateNumbers,
        startOfRental: new Date(user.startOfRental).toLocaleDateString().replaceAll('. ', '-').replace('.', ''),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const updatePerson = async () => {
    setSubmitting(true);    
    await axios.post(`${API_ROOT}/edit-user`, {
      id,
      name: state.personalName,
      email: state.email,
      address: state.personalAddress,
      id_card: state.personalId,
      contactPhones: state.contactPhones,
      plateNumbers: state.plateNumbers,
      startOfRental: state.startOfRental,
      billingFrequency: state.billingFrequency,
      selectedGarages,
    });
    await refetch();
    alert("Felhasználó frissítve lett!");
    setSubmitting(false);
    navigate("/admin/users");
  };

  if (!user) return <div>Adatok betöltése...</div>;

  return (
    <>
      <label>Név:</label>
      <TextBox
        value={state.personalName}
        onChange={(e) => dispatch({ personalName: e })}
        name="PersonalName"
        placeholder="Teszt Elek"
      />
      <label>Lakcím:</label>
      <TextBox
        value={state.personalAddress}
        onChange={(e) => dispatch({ personalAddress: e })}
        name="PersonalAddress"
        placeholder="1037 Budapest, Teszt utca 4"
      />
      <label>Személyigazolvány szám:</label>
      <TextBox
        value={state.personalId}
        onChange={(e) => dispatch({ personalId: e })}
        name="PersonalId"
        placeholder="232323IA"
      />
      <div className="multipleInputContainer">
        <label>Kapcsolattartó Telefonszám:</label>
        <div className="multipleInputButtonsContainer">
          <button
            disabled={state.contactPhones?.length <= 1}
            onClick={() => {
              const temp = [...state.contactPhones];
              temp.pop();
              dispatch({ contactPhones: temp });
            }}
          >
            -
          </button>
          <br />
          <button
            disabled={state.contactPhones?.length >= 3}
            onClick={() =>
              dispatch({
                contactPhones: [...state.contactPhones, ""],
              })
            }
          >
            +
          </button>
        </div>
      </div>
      {state.contactPhones?.map((number, i) => (
        <TextBox
          key={i}
          value={number}
          onChange={(e) => {
            const temp = [...state.contactPhones];
            temp[i] = e;
            dispatch({ contactPhones: temp });
          }}
          name="PersonalContactPhone"
          placeholder="+36301111232"
        />
      ))}
      <div className="multipleInputContainer">
        <label>Rendszám:</label>
        <div className="multipleInputButtonsContainer">
          <button
            disabled={state.plateNumbers.length <= 1}
            onClick={() => {
              const temp = [...state.plateNumbers];
              temp.pop();
              dispatch({ plateNumbers: temp });
            }}
          >
            -
          </button>
          <br />
          <button
            disabled={state.plateNumbers.length >= 3}
            onClick={() =>
              dispatch({
                plateNumbers: [...state.plateNumbers, ""],
              })
            }
          >
            +
          </button>
        </div>
      </div>
      {state.plateNumbers.map((number, i) => (
        <TextBox
          key={i}
          value={number}
          onChange={(e) => {
            const temp = [...state.plateNumbers];
            temp[i] = e;
            dispatch({ plateNumbers: temp });
          }}
          name="PersonalPlateNumber"
          placeholder="LDX - 234, AA - AA - 123"
        />
      ))}
      <label> E-mail cím:</label>
      <TextBox
        type="email"
        value={state.email}
        onChange={(e) => dispatch({ email: e })}
        name="email"
        placeholder="tesztelek@gmail.com"
      />
      <label>Beállók száma:</label>
      <br />
      <Select
        isMulti
        options={[...availableGarages, ...userGarages].map(({ name, id }) => ({ label: name, value: id }))}
        onChange={(v) => setSelectedGarages(v.map(({ value }) => value))}
        value={selectedGarages?.map((garage) => ({ label: garages.find((g) => g.id === garage)?.name, value: garage}))}
      />
      <br />
      <br />
      <label>Bérlés kezdete:</label>
      <TextBox
        type="date"
        value={state.startOfRental}
        onChange={(e) => dispatch({ startOfRental: e })}
        name="StartOfRental"
      />
      <label>Számlázási gyakoriság:</label>
      <br />
      <select
        value={state.billingFrequency}
        onChange={(e) => dispatch({ billingFrequency: e.target.value })}
      >
        <option value="MONTHLY">Havonta</option>
        <option value="QUARTERLY">Negyed Évente</option>
        <option value="HALFLY">Fél Évente</option>
        <option value="YEARLY">Évente</option>
      </select>
      <br />
      <br />
      <Button className="adatCancel" onClick={() => navigate("/admin")}>
        Mégse
      </Button>
      <Button
        disabled={submitting || !state.personalName || !state.personalAddress ||
          !state.personalId || !state.contactPhones[0].length || !state.plateNumbers[0].length ||
          !state.email || !selectedGarages?.length || !state.startOfRental}
        className="adatTovabb"
        onClick={updatePerson}
      >
        Mentés
      </Button>
    </>
  );
};

export default EditPersonalDetails;
