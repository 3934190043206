import React from 'react';
import Header from '../logout/header/header';
import Footer from '../logout/footer/footer';

const InternalServerError = () => {
  return (
    <>
    <Header />
    <div id="pageMain">
      <section id="section404">
        <div className="szerzodes404">
          <div className="window404">
            <div className="text404">500</div>
            <h2 className="description404">A szerver nem elérhető</h2>
          </div>
        </div>
      </section>
    </div>
    <Footer />
    </>
  );
};

export default InternalServerError;