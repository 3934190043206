import React, { createContext, useReducer, Dispatch, ComponentType } from 'react';

const initialState = {
  personalName: "",
  personalAddress: "",
  personalId: "",
  contactPhones: [""],
  plateNumbers: [""],
  email: "",
  password: "",
  startOfRental: "",
  billingFrequency: "MONTHLY",
};

type State = typeof initialState;
type Action = Partial<State>;

const AddUserPersonalContext = createContext<{ state: State; dispatch: Dispatch<Action> }>({
  state: initialState,
  dispatch: () => null,
});

const reducer = (s: State, a: Action) => ({ ...s, ...a });

export const withAddUserPersonalContext = (Component: ComponentType) => () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AddUserPersonalContext.Provider value={{ state, dispatch }}>
      <Component />
    </AddUserPersonalContext.Provider>
  );
};

export default AddUserPersonalContext;
