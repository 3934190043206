import { BillingFrequency } from "../hooks/useFirebaseAuthentication";

export const getBillingFrequency = (billingFrequency?: BillingFrequency) => {
    switch (billingFrequency) {
      case 'MONTHLY':
        return 'Havonta';
      case 'QUARTERLY':
        return 'Negyedévente';
      case 'HALFLY':
        return 'Félévente';
      case 'YEARLY':
        return 'Évente';
    
      default:
        return '-';
    }
}
