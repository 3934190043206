import React from 'react';
import Footer from '../logout/footer/footer';
import Header from '../logout/header/header';
import '../logout/logout.css';

const Confirmation = () => {
  return (
    <>
    <Header />
      <div id="pageMain">
        <section id="sectionRegSuccess" className="sargaHatter">
            <div id="regSuccessWrapper" className="myWhiteWindow">
            <h3>Gratulálunk!</h3>
            <div className="windowText">
                Az e-mail címére küldött levelünkben lévő linken aktiválhatja a fiókját.
            </div>
                <img src={require('../../assets/images/boritek.svg').default} alt='boritek' className="myBoritek" />
            </div>
        </section>
      </div>
    <Footer />
    </>    
  );
};

export default Confirmation;