import React, { useState } from 'react';
import SendMessages from '../../../components/contact/contact_successful';
import ContactForm from '../../../components/contact/contact';

export const Contact = () => {
  const [stateType, setStateType] = useState<"" | "successful">("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("Kapcsolatfelvétel");
  const [message, setMessage] = useState("");

  const reset = () => {
    setName("");
    setEmail("");
    setSubject("");
    setMessage("");
    setStateType("");
  }

    if (stateType === "successful") {
      return <SendMessages reset={reset} />;
    } else {
      return <ContactForm
        name={name}
        email={email}
        subject={subject}
        message={message}
        setName={setName}
        setEmail={setEmail}
        setSubject={setSubject}
        setMessage={setMessage}
        setStateType={setStateType}
      />;
    }
}

export default Contact;