import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

import Button from '../../../../components/button/button';
import TextBox from '../../../../components/textbox/textbox';
import AddUserPersonalContext from '../../../../contexts/AddUserPersonalContext';
import { API_ROOT } from '../../../../utils/constants';
import useGarages from '../../../../hooks/useGarages';


const AddPersonalDetails: React.FC<{ registrationType: string }> = ({ registrationType }) => {
    const [loading, setLoading] = useState(false);
    const { state, dispatch } = useContext(AddUserPersonalContext);
    const garages = useGarages().filter((g) => g.available);
    const [selectedGarages, setSelectedGarages] = useState<string[]>();
    const navigate = useNavigate();

    const RegPerson = async () => {
        setLoading(true)
        await axios.post(`${API_ROOT}/create-user`, {
            name: state.personalName,
            email: state.email,
            password: state.password,
            address: state.personalAddress,
            id_card: state.personalId,
            contactPhones: state.contactPhones,
            plateNumbers: state.plateNumbers,
            startOfRental: state.startOfRental,
            billingFrequency: state.billingFrequency,
            registertypeof: registrationType,
            selectedGarages,
        });
        alert('Felhasználó regisztrálva lett!')
        setLoading(false);
        navigate('/admin/users');
    };
    return (
        <>
            <label>Név:</label>
            <TextBox value={state.personalName} onChange={(e) => dispatch({ personalName: e })} name="PersonalName" placeholder="Teszt Elek" />
            <label>Lakcím:</label>
            <TextBox value={state.personalAddress} onChange={(e) => dispatch({ personalAddress: e })} name="PersonalAddress" placeholder="1037 Budapest, Teszt utca 4" />
            <label>Személyigazolvány szám:</label>
            <TextBox value={state.personalId} onChange={(e) => dispatch({ personalId: e })} name="PersonalId" placeholder="232323IA" />
            <div className='multipleInputContainer'>
                <label>Kapcsolattartó Telefonszám:</label>
                <div className="multipleInputButtonsContainer">
                    <button disabled={state.contactPhones.length <= 1} onClick={() => {
                        const temp = [...state.contactPhones]
                        temp.pop();
                        dispatch({ contactPhones: temp })
                    }}
                    >-</button>
                    <br />
                    <button disabled={state.contactPhones.length >= 3} onClick={() => dispatch({ contactPhones: [...state.contactPhones, ''] })}>+</button>
                </div>
            </div>
            {state.contactPhones.map((number, i) => (
                <TextBox key={i} value={number}
                    onChange={(e) => {
                        const temp = [...state.contactPhones];
                        temp[i] = e;
                        dispatch({ contactPhones: temp })
                    }}
                    name="PersonalContactPhone"
                    placeholder="+36301111232"
                />
            ))}
            <div className='multipleInputContainer'>
                <label>Rendszám:</label>
                <div className="multipleInputButtonsContainer">
                    <button disabled={state.plateNumbers.length <= 1} onClick={() => {
                        const temp = [...state.plateNumbers]
                        temp.pop();
                        dispatch({ plateNumbers: temp })
                    }}
                    >-</button>
                    <br />
                    <button disabled={state.plateNumbers.length >= 3} onClick={() => dispatch({ plateNumbers: [...state.plateNumbers, ''] })}>+</button>
                </div>
            </div>
            {state.plateNumbers.map((number, i) => (
                <TextBox key={i} value={number}
                    onChange={(e) => {
                        const temp = [...state.plateNumbers];
                        temp[i] = e;
                        dispatch({ plateNumbers: temp })
                    }}
                    name="PersonalPlateNumber"
                    placeholder="LDX - 234, AA - AA - 123"
                />
            ))}
            <label> E-mail cím:</label>
            <TextBox type="email" value={state.email} onChange={(e) => dispatch({ email: e })} name="email" placeholder="tesztelek@gmail.com" />
            <label>Jelszó:</label>
            <TextBox value={state.password} onChange={(e) => dispatch({ password: e })} name="password" placeholder="Minimum 8 karakter kis és nagybetű, illetve szám" />
            <label>Beállók száma:</label>
            <br />
            <Select isMulti options={garages.map(({ name, id }) => ({ label: name, value: id }))} onChange={(v) => setSelectedGarages(v.map(({ value }) => value))} />
            <br />
            <br />
            <label>Bérlés kezdete:</label>
            <TextBox type="date" value={state.startOfRental} onChange={(e) => dispatch({ startOfRental: e })} name="StartOfRental" />
            <label>Számlázási gyakoriság:</label>
            <br />
            <select value={state.billingFrequency} onChange={(e) => dispatch({ billingFrequency: e.target.value })}>
                <option value="MONTHLY">Havonta</option>
                <option value="QUARTERLY">Negyed Évente</option>
                <option value="HALFLY">Fél Évente</option>
                <option value="YEARLY">Évente</option>
            </select>
            <br />
            <br />
            <Button className="adatCancel" onClick={() => navigate('/admin')}>Mégse</Button>
            <Button disabled={
                loading || !registrationType || !state.personalName || !state.personalAddress ||
                !state.personalId || !state.contactPhones[0].length || !state.plateNumbers[0].length ||
                !state.email || !state.password || !selectedGarages?.length || !state.startOfRental
            } className="adatTovabb" onClick={RegPerson}>Új ügyfél felvétel</Button>
        </>
    );
}

export default AddPersonalDetails;