import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from 'firebase/auth';
import TextBox from '../../../components/textbox/textbox';
import { auth } from '../../../config/config';
import Button from '../../../components/button/button';
import LoginContext from '../../../contexts/LoginContext';
import getUser from '../../../utils/getUser';
import useReservations from '../../../hooks/useReservations';


const Login: React.FC = () => {
    const { email, pwd, setEmail, setPwd, setSelectorType } = useContext(LoginContext);
    const reservations = useReservations();
    const navigate = useNavigate();
    
        const Login = async () => {
            signInWithEmailAndPassword(auth, email, pwd)
                .then(async (response) => {
                    if (!auth.currentUser?.emailVerified) {
                        alert("Nem aktíváltad fiókodat!");
                    } else {
                        localStorage.setItem('myPage.expectSignIn', '1');
                        const user = await getUser(response.user.uid);
                        const haveReservation = !!reservations.find((r) => r.userId === auth.currentUser?.uid);
                        
                        if (!user) {
                            navigate('/');
                        }
                        
                        else if (user.role === 'admin') {
                            navigate('/admin');
                        } else {
                            if (!haveReservation) {
                                navigate('/rent');
                            } else {
                                navigate('/profile');
                            }
                        }
                    }
                })
                .catch((error) => {
                    localStorage.removeItem('myPage.expectSignIn');
                    console.log(error);
                }
            );
        };
        return (
        <>
            <section id="sectionRegisztralj">
                <div id="regisztralj">
                <div id="regisztraljFeherBack">
                     <h3>Lépjen be</h3>
                     <p>és foglaljon most.</p>
                    <div id="regGombok">
                        <button onClick={() => setSelectorType('signup')} id="regButton">Regisztráció</button>
                        <button onClick={() => setSelectorType('login')} id="loginButton" className="active">Belépés</button>
                    </div>
                    <div className="nagyGombWrapper">
                        <div className="myWindow">
                            <div className="formWrapper">
                                <label>
                                <img src={require('../../../assets/images/ikon-level-fekete.svg').default} alt='ikon-level-fekete' /> Email cím
                                </label>
                                    <TextBox value={email} onChange={setEmail} type="email" className="vgInput" name="email" />
                                <label>
                                <img src={require('../../../assets/images/ikon-kulcs.svg').default} alt='ikon-kulcs' /> Jelszó
                                </label>
                                    <TextBox value={pwd} onChange={setPwd} type="password" className="vgInput" name="password" />
                                <div className="loginRegOffer" onClick={() => setSelectorType('forgottenpwd')}>Elfelejtette a jelszavát?</div>
                                <Button disabled={!email || !pwd} className="loginRegRegButton" onClick={() => Login()}>Belépés</Button>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </section>
        </>
        );
    }

export default Login;