import React, {useContext, useEffect, useState} from 'react';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import Logo from '../../../assets/images/logo.svg';
import { Helmet } from "react-helmet";
import AuthContext from '../../../contexts/AuthContext';

const AdminSidebar: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [show, setShow] = useState(false);
    const { userNode } = useContext(AuthContext);
    const navigate = useNavigate();
    
    
    useEffect(() => {
      if (!userNode) return navigate('/');
      if(userNode.role === "admin"){
        return;
      } else {
        navigate('/profile');
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userNode]);

    return (
      <>
      <Helmet>
        <link rel="stylesheet" href="sidebar.css" />
      </Helmet>  
        <main className={show ? "space-toggle" : undefined}>
          <header className={`header ${show ? "space-toggle" : null}`}>
            <div className="header-toggle" onClick={() => setShow(!show)}>
              <i className="fas fa-bars"></i>
            </div>
          </header>
          <aside className={`sidebar ${show ? "show" : null}`}>
            <nav className="nav">
              <div>
                <Link to='/' className="nav-logo">
                  <img className="nav-logo-icon" alt="logo" src={Logo} />
                  <span className="nav-logo-name">Vasgarázs</span>
                </Link>
                <div className="nav-list">
                  <NavLink to='/admin/users' onClick={ () => window.location.replace('/admin')} className="nav-link active" >
                    <i className="fas fa-user nav-link-icon"></i>
                    <span className="nav-link-name">Ügyfelek</span>
                  </NavLink>
                  <NavLink to='/admin/garage' className="nav-link active">
                    <i className="fas fa-parking nav-link-icon"></i>
                    <span className="nav-link-name">Garázsok</span>
                  </NavLink>
                  <NavLink to='/admin/news' className="nav-link active">
                    <i className="fas fa-newspaper nav-link-icon"></i>
                    <span className="nav-link-name">Hírek</span>
                  </NavLink>
                </div>
              </div>  
            </nav>
          </aside>
            {children}
          <footer>
            <div> 2022 © Vasgarázs by <a href="https://webclouddesign.hu" target="_blank" rel="noreferrer">Nagy Sebestyén E.V</a></div>
          </footer>
        </main>
      </>
    );
};

export default AdminSidebar;