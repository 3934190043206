import React, { createContext, useReducer, Dispatch, ComponentType } from 'react';

const initialState = {
  step: 0,
  personalName: "",
  personalAddress: "",
  personalId: "",
  contactPhones: [""],
  plateNumbers: [""],
  email: "",
  password: "",
};

type State = typeof initialState;
type Action = Partial<State>;

const SignupPersonContext = createContext<{ state: State; dispatch: Dispatch<Action> }>({
  state: initialState,
  dispatch: () => null,
});

const reducer = (s: State, a: Action) => ({ ...s, ...a });

export const withSignupPersonContext = (Component: ComponentType) => () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SignupPersonContext.Provider value={{ state, dispatch }}>
      <Component />
    </SignupPersonContext.Provider>
  );
};

export default SignupPersonContext;
