import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { db } from "../../../config/config";
import { collection, onSnapshot, query } from "firebase/firestore";
import { IUser } from "../../../hooks/useFirebaseAuthentication";
import { API_ROOT } from "../../../utils/constants";
import AuthContext from "../../../contexts/AuthContext";
import useGarages from "../../../hooks/useGarages";
import useReservations from "../../../hooks/useReservations";
import { getBillingFrequency } from "../../../utils/helperFunctions";

const CustomLoader = () => (
  <div style={{ padding: "24px", display: "flex" }}>
    <div className="spinner" />
  </div>
);

interface IRow {
  id: string;
  data: IUser;
}

const Users = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState<any>([]);
  const { user } = useContext(AuthContext);
  const garages = useGarages();
  const reservations = useReservations();

  const onDelete = async (row: IRow) => {
    if (!window.confirm('Biztos törlöd? ' + row.data.email)) return;
    await axios.post(`${API_ROOT}/delete-user`, { id: row.id });
    alert('Felhasználó törölve!');
  }

  const getDate = async () => {
    const q = query(collection(db, "users"));
    onSnapshot(q, (data: { docs: any[] }) => {
      setUsers(
        data.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );
    });
  };

  useEffect(() => {
    getDate();
  }, []);

  const columns = [
    {
      name: "Beállószám",
      selector: (row: IRow) => {
        const garageIds = reservations.filter((r) => r.userId === row.id).map((r) => r.garageId);
        return garageIds.map((id) => garages.find((g) => g.id === id)?.name).join(', ');
      },
    },
    {
      name: "Bérlő neve",
      selector: (row: IRow) => {
        return row.data.name;
      },
    },
    {
      name: "Számlázási cím",
      selector: (row: IRow) => {
        return row.data.address;
      },
    },
    {
      name: "Rendszámok",
      selector: (row: IRow) => {
        return row.data.plateNumbers.join(', ');
      },
    },
    {
      name: "Telefonszáma",
      selector: (row: IRow) => {
        return (row.data.registertypeof === 'person' ? row.data.contactPhones?.join(', ') : row.data.contactPhones?.join(', ')) || '-';
      },
    },
    {
      name: "E-mail címe",
      selector: (row: IRow) => {
        return row.data.email;
      },
    },
    {
      name: "Bérlés kezdete",
      selector: (row: IRow) => {
        return row.data.startOfRental ? new Date(row.data.startOfRental).toLocaleDateString() : '-';
      },
    },
    {
      name: "Számlázási gyakoriság",
      selector: (row: IRow) => {
        return getBillingFrequency(row.data.billingFrequency);
      },
    },
    {
      name: "Regisztrált Telefonszámok",
      selector: (row: IRow) => {
        return (row.data.registertypeof === 'person' ? row.data.contactPhones?.join(', ') : row.data.contactPhones?.join(', ')) || '';
      },
    },
    {
      name: "Funkciók",
      selector: (row: IRow) => {
        return row.data.name;
      },
      cell: (row: IRow) => (
        <>
          <button className="admin_edit_button" onClick={() => navigate('/admin/users/edit/' + row.id)}><i className="fas fa-pencil-alt"></i></button>
          {row.id !== user?.uid && <button className="admin_delite_button" onClick={() => onDelete(row)}><i className="far fa-trash-alt"></i></button>}
        </>
      ),
    }
  ];

  return (
    <>
      <div className="wrapper">
        <div className="context">
          <h1 className="admin_title">Ügyfelek</h1>
          <button className="admin_add_button">
            <Link to="/admin/users/add">Új Ügyfél</Link>
          </button>
        </div>
        {!users.length ? (
          <CustomLoader />
        ) : (
          <DataTable data={users} columns={columns} />
        )}
      </div>
    </>
  );
};

export default Users;