import React from 'react';
import { Link } from 'react-scroll';
import { Link as Linkto } from 'react-router-dom';
import Button from '../../../components/button/button';
import GarageItems from '../../../components/garage_items/garage_items';

const LogoutRent = () => {

  return (
    <section id="sectionFoglalas">
      <div className="titlePart">
        <h2>Garázs Foglalás</h2>
      </div>
      <div id="foglalasWrapper">
        <div id="foglalasInner">
          <div className="row">
            <div className="col-xs-12 col-md-8" id="grid8AbraWrapper">
              {(localStorage.getItem('myPage.expectSignIn')) ? 
              <div id="foglalasWhiteBox">
                <div id="foglalasWhiteBoxContent" className="whiteBoxContent">
                  A foglaláshoz
                  <br /> kattintson a gombra
                  <br />
                  <Linkto to="/rent">
                    <Button className="standardButton">Tovább a Foglaláshoz</Button>
                  </Linkto>
                </div>
              </div>
              : 
              <div id="foglalasWhiteBox">
                <div id="foglalasWhiteBoxContent" className="whiteBoxContent">
                  A foglaláshoz
                  <br />
                  <Link to="sectionRegisztralj" spy={true} smooth={true}>
                    <Button className="standardButton">REGISZTRÁCIÓ</Button>
                  </Link>
                  <br /> szükséges.
                </div>
              </div>
              }
              <GarageItems />
            </div>
            <div className="col-xs-12 col-md-4">
              <div className="jeloleseinkWrapper">
                <h3 className="headingAraink">Áraink</h3>
                <div className="arainkAr">23.000 Ft</div>
                <h3 className="headingJeloleseink">Jelöléseink</h3>
                <div className="jelolesSor">
                  <div className="jelolesCar">
                    <img src={require('../../../assets/images/car1.svg').default} alt='car1' />
                  </div>
                  Foglalt helyek
                </div>
                <div className="jelolesSor">
                  <div className="jelolesCar">
                    <img src={require('../../../assets/images/car2.svg').default} alt='car2' />
                  </div>
                  Ön által választott hely
                </div>
                <div className="jelolesSor">
                  <div className="jelolesCar">
                    <img src={require('../../../assets/images/car3.svg').default} alt='car3' />
                  </div>
                  Ön által használt garázs
                </div>
                <div className="jelolesSor">
                  <div className="jelolesCar jelolesNyil">
                    <img src={require('../../../assets/images/arrow.svg').default} alt='arrow' />
                  </div>
                  Bejárat
                </div>
                {(localStorage.getItem('myPage.expectSignIn')) ? 
                  <div className="garazsGombok">
                    <Linkto to="/rent">
                      <Button className='standardButton tovabbButton'>Tovább a Foglaláshoz</Button>
                    </Linkto>
                  </div>
                :
                  null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>    
  );
};

export default LogoutRent;