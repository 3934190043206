import React from "react";
import IHtmlAttributes from "../htmlattributes";


interface ITextBoxProps extends IHtmlAttributes {
    value: string;
    onChange: (value: string) => void;
    className?: string;
    alt?: string;
    name?: string;
    type?: string;
    style?: React.CSSProperties;
}

export default class TextBox extends React.Component<ITextBoxProps> {

    public render() {
        return (
            <input {...this.props} className={this.props.className} style={this.props.style} alt={this.props.alt} name={this.props.name} type={this.props.type} onChange={({ target: { value } }) => this.props.onChange(value)} value={this.props.value} />
        )
    }
}