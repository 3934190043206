import { initializeApp } from "firebase/app";
import {
    getAuth,
  } from "firebase/auth";
  import {
    getFirestore
  } from "firebase/firestore";
  import { getStorage } from "firebase/storage";

  
export const config = {
    apiKey: "AIzaSyAIsmx9jshFWFqURgnXZ7JqOtTBBcooiOY",
    authDomain: "vasgarazs-app.firebaseapp.com",
    projectId: "vasgarazs-app",
    storageBucket: "vasgarazs-app.appspot.com",
    messagingSenderId: "952502542226",
    appId: "1:952502542226:web:1e5245e6bd254bbf7eab4b"
};

const app = initializeApp(config);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export {
  auth,
  db,
  storage
};
