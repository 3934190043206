import React from 'react';
import Footer from '../footer/footer';
import Header from '../header/header';
import '../logout/logout.css';

const Cookie = () => {
  return (
    <>
    <Header />
      <div id="pageMain">
        <section id="sectionSzerzodes" className=" cookieWrapper">
          <h2 className="titlePart titlePart2  text-center"> Cookie-k kezelése</h2>
          <h3 className="subTitle  text-center">Vasgarázs</h3>
          <div className="szerzodesWrapper text-left">
            <p>
              A Vasgarázs által üzemeltetett weboldalakra való belépéssel, ha ezt az
              Ön által használt böngésző beállítások engedik, vagy azt Ön az oldal
              első látogatásakor kifejezetten jóváhagyja, a weboldal automatikusan
              elmenthet információkat az Ön számítógépéről, illetve a böngészésre
              használt eszközéről (táblagép, okostelefon, hordozható okos eszközök),
              illetve azon célból ún. számítógépes Cookie-t (továbbiakban: “Sütik”)
              vagy más hasonló programokat helyezhet el.
            </p>
            <p>
              A Süti egy olyan fájl, amely akkor kerülhet a számítógépre vagy a
              böngészésre használt eszközre, amikor Ön egy weboldalt látogat meg.
            </p>
            <p>
              Általánosságban a Sütik vagy a más hasonló programok megkönnyítik a
              honlap használatát, elősegítik, hogy a honlap a Látogatók számára igazi
              webes élményt és hatékony információforrást jelentsen, illetve
              biztosítják a honlap üzemeltetője részére az oldal működésének
              ellenőrzését, a visszaélések megakadályozását és az oldalon nyújtott
              szolgáltatások zavartalan és megfelelő színvonalú biztosítását.
            </p>
            <p>
              A Sütik használatának elfogadása, engedélyezése nem kötelező. A Ön
              vissza tudja utasítani a Sütik használatát számítógépe vagy a
              böngészésre használt más eszköze, illetve a honlap eléréséhez használt
              böngészője beállításain keresztül, azonban ilyen esetben, Sütik nélkül
              nem tudja majd maradéktalanul kihasználni honlapunk minden funkcióját,
              szolgáltatását, illetve ezek alkalmazása nélkül a Vasgarázs sem tudja
              garantálni Önnek a honlap teljes körű használatát.
            </p>
            <p>
              <b>
                A Sütik által Önről, illetve az Ön által használt számítógépről,
                illetve a böngészésre használt eszközről a következő adatokat
                rögzítjük és kezeljük:
              </b>
            </p>
            <ul>
              <li>az Ön által használt IP-cím,</li>
              <li>a böngésző típusa,</li>
              <li>
                a böngészésre használt eszköz operációs rendszerének jellemzői
                (például típus, beállított nyelv, lokalizációja),
              </li>
              <li>a látogatás pontos időpontja,</li>
              <li>az előzőleg látogatott oldal címe,</li>
              <li>
                a meglátogatott oldal, aloldal, használt funkció vagy szolgáltatás,
              </li>
              <li>az oldalon töltött idő.</li>
            </ul>
            <p>
              <b>
                A Vasgarázs az alábbi tevékenységek megfigyelésére használja a
                Sütiket:
              </b>
            </p>
            <ul>
              <li>
                információt ad arról, hogy Ön járt-e már honlapunkon, illetve, hogy
                milyen aloldalakat vagy más domainhez tartozó oldalakat látogatott
                meg,
              </li>
              <li>milyen funkciókat, szolgáltatásokat vett igénybe,</li>
              <li>
                milyen információk után érdeklődött, vagy melyek azok, amelyek
                leginkább érdekelhetik Önt.
              </li>
            </ul>
            <p>
              Azaz a Sütiket a Vasgarázs alapvetően információgyűjtésre használja fel
              a még jobb és minőségi felhasználói élmény biztosítása és a honlap
              fejlesztése céljából. Ezen Sütik egy része feltétlenül szükségesek
              ahhoz, hogy Önök böngészhessék a www.vasgarazs.hu honlapját,
              maradéktalanul és zökkenőmentesen használhassák annak funkcióit, a
              honlapon keresztül elérhető szolgáltatásokat.
            </p>
            <p>
              Ezek az ún. munkamenet Sütik, melyek többek között lehetővé teszik a
              Látogató által adott oldalon, funkcióban vagy szolgáltatásban végzett
              műveletek megjegyzését egy látogatás során. Ezen Sütik érvényességi
              ideje kizárólag az aktuális látogatásra vonatkozik, a munkamenet
              végeztével, illetve a böngésző bezárásával a Sütik e fajtája
              automatikusan törlődik a számítógépről, ha az így van beállítva. A
              tárolás a felhasználó számítógépén történik.
            </p>
            <p>
              Az ún. teljesítményt biztosító Sütiket arra használja a Vasgarázs, hogy
              információt gyűjtsön azzal kapcsolatban, hogyan használják látogatóink a
              www.vasgarazs.hu honlapját.
            </p>
            <p>
              Ezek a Sütik olyan információkat gyűjtenek, mint például melyik oldalt,
              aloldalt nézte meg Ön, annak mely részére kattintott, hány oldalt vagy
              aloldalt keresett fel, milyen más oldalakat látogatott, milyen hosszú
              volt az egyes oldalak megtekintési ideje stb. Mindezek célja honlapunk
              és a rajta megtalálható funkciók szolgáltatások fejlesztése,
              optimalizációja és ez által a felhasználói élmény javítása, a zavartalan
              és megfelelő színvonalú szolgáltatásnyújtás biztosítása.
            </p>
            <p>
              A weboldalak megfelelő működése érdekében a legtöbb böngésző
              automatikusan elfogadja a Sütiket. Fontos kiemelni, hogy ha néhány vagy
              az összes Süti tiltása/eltávolítása mellett dönt – amit a böngészője
              beállításai között bármikor megtehet –, az megváltoztathatja a weboldal
              működését, ami a számítógépe többi felhasználóját is érinteni fogja.
            </p>
            <p>
              A felhasználó beállíthatja webes keresőjét úgy, hogy elfogadjon minden
              cookie-t, elutasítsa mindet, vagy értesítse a felhasználót, ha cookie
              érkezik a gépére. A beállítási lehetőségek általában a böngésző „Opciók”
              vagy „Beállítások” menüpontjában találhatók.
            </p>
            <h3>Harmadik fél által igénybe vett Sütik</h3>
            <p>
              Tájékoztatjuk, hogy a jelen weboldal használata során harmadik féltől
              származó Sütik is kerülhetnek a böngészésre használt eszközére, amely a
              tartalmak közösségi oldalakon való megosztását, a látogatottsági
              statisztikák készítését vagy a Vasgarázs marketing tevékenységének a
              támogatását segítik elő.
            </p>
            <p>
              A Vasgarázs a Google Analytics Sütiket azzal kapcsolatban alkalmazza,
              hogy a látogatóink hogyan használják weboldalunkat. Ezek a Sütik nem
              alkalmasak arra, hogy be tudják Önt személy szerint azonosítani, csak
              olyan tudnivalókat gyűjtenek, mint például, hogy Ön melyik oldalt nézte
              meg, a weboldal mely részére kattintott és mennyi oldalt keresett fel,
              valamint milyen hosszú volt az egyes munkamenetek megtekintési ideje.
              Ezeket a kapott információkat csak és kizárólag a Vasgarázs weboldalának
              fejlesztésének, és a böngészők számára biztosított élmények javításának
              céljával használja.
            </p>
            <div className="text-right">Vasgarázs</div>
          </div>
        </section>
      </div>  
    <Footer />
    </>  
  );
};

export default Cookie;