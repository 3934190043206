import React, { useContext } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Logout from './pages/logout/logout';
// import NotFound from './pages/404/404';
import InternalServerError from './pages/500/500';
import Privacy from './pages/privacy/privacy';
import Terms from './pages/terms/terms';
import Cookie from './pages/cookie/cookie';
import Impress from './pages/impress/impress';
import PaySuccess from './pages/login/paysuccess/paysuccess';
import Confirmation from './pages/confirmation/confirmation';
import DataEdit from './pages/dataedit/dataedit';
// import { initializeApp } from 'firebase/app';
// import { config } from './config/config';
import AuthRoute from './components/auth/AuthRoute';
import Profile from './pages/login/profile/profile';
import Rent from './pages/login/rent/rent';
import AuthContext, { withAuthContext } from './contexts/AuthContext';
import { withRentContext } from './contexts/RentContext';
import { withLoginContext } from './contexts/LoginContext';
import { withSignupCompanyContext } from './contexts/SignupCompanyContext';
import { withSignupPersonContext } from './contexts/SignupPersonContext';
import { withAddUserPersonalContext } from './contexts/AddUserPersonalContext';
import Users from './pages/admin/users/users';
import AdminSidebar from './pages/admin/sidebar/sidebar';
import EditUser from './pages/admin/users/edit_user/edit_user';
import NewsPage from './pages/logout/news/NewsPage';
import AddUser from './pages/admin/users/add_user/add_user';
import { withAddCompanyContext } from './contexts/AddCompanyContext';

// initializeApp(config);

const Application = () => {
  const { isLoading } = useContext(AuthContext);
  if (isLoading) return <>Loading...</>

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Logout />} />
        {/* <Route path="/" element={<NotFound />} /> */}
        <Route path="admin/*" element={<Admin />} />
        <Route path="500" element={<InternalServerError />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="terms" element={<Terms />} />
        <Route path="cookie" element={<Cookie />} />
        <Route path="impress" element={<Impress />} />
        <Route path="paysuccess" element={<PaySuccess />} />
        <Route path="confirmation" element={<Confirmation />} />
        <Route path="dataedit" element={<DataEdit />} />
        <Route path="news/:id" element={<NewsPage />} />
        <Route path="rent"
          element={
            <AuthRoute>
              <Rent />
            </AuthRoute>
          }
        />
        <Route path="/profile"
          element={
            <AuthRoute>
              <Profile />
            </AuthRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  )
};

const Admin = () => (
  <AdminSidebar>
    <Routes>
      <Route path="*" element={<Users />} />
      <Route path="users/add" element={<AddUser />} />
      <Route path="users/edit/:id" element={<EditUser />} />
      <Route path="users" element={<Users />} />
    </Routes>
  </AdminSidebar>
);



export default withRentContext(
  withLoginContext(
    withAuthContext(
      withSignupCompanyContext(
        withSignupPersonContext(
          withAddCompanyContext(
            withAddUserPersonalContext(() => <Application />)))))));
