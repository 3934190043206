import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import LoginContext from '../../contexts/LoginContext';

const Footer = () => {
  const { setSelectorType } = useContext(LoginContext);
  return (
    <footer id="mainFooter">
      <div className="footerWrapper">
        <div className="row">
          <div className="col-md-2 col-xs-12 col-sm-12 text-center">
            <Link to="/">
              <img src={require('../../assets/images/logo.svg').default} alt='footerlogo' id="topLogo" />
            </Link>
          </div>
          <div className="col-md-3 col-xs-6 footerDesktop">
            <ul className="footerMenuList ">
              <li className="footerLoginLink">
                <img src={require('../../assets/images/ikon-login2.svg').default} alt='ikonlogin2' className="headericon " />
                <span><Link to="/"  onClick={() => setSelectorType('login')}>Bejelentkezés</Link></span>
              </li>
              <li>
                <img src={require('../../assets/images/ikon-rolunk.svg').default} alt='ikonrolunk' className="headericon" />
                <span><Link to="/" >Rólunk</Link></span>
              </li>
              <li>
                <img src={require('../../assets/images/ikon-mukodesunk.svg').default} alt='ikonmukodesunk' className="headericon" />
                <span><Link to="/" >Működésünk</Link></span>
              </li>
              <li>
                <img src={require('../../assets/images/ikon-berles.svg').default} alt='ikonberles' className="headericon" />
                <span><Link to="/" >Bérlés</Link></span>
              </li>
              <li>
                <img src={require('../../assets/images/ikon-kapcsolat.svg').default} alt='ikonkapcsolat' className="headericon" />
                <Link to="/" >Kapcsolat</Link>
              </li>
              <li>
                <img src={require('../../assets/images/ikon-hirek.svg').default} alt='ikonhirek' className="headericon" />
                <span><a href="/">Hírek</a></span>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-xs-6 footerDesktop">
            <div className="footerMiddleLinks">
              {/* <Link to="/">Hibajelentés</Link> */}
              <br />
              <br />
              <Link to="/impress">Impresszum</Link>
              <Link to="/privacy">GDPR</Link>
              <Link to="/terms">ÁSZF</Link>
              <Link to="/cookie">Süti kezelő</Link>
            </div>
          </div>
          <div className="col-xs-6 col-sm-4 footerMobile">
            <ul className="footerMenuList ">
              <li className="footerLoginLink">
                <span><Link to="/"  onClick={() => setSelectorType('login')}>Bejelentkezés</Link></span>
              </li>
              <li>
                <span><Link to="/" >Rólunk</Link></span>
              </li>
              <li>
                <span><Link to="/" >Működésünk</Link></span>
              </li>
              <li>
                <span><Link to="/" >Bérlés</Link></span>
              </li>
              <li>
                <span><Link to="/" >Kapcsolat</Link></span>
              </li>
              <li>
                <span><a href="/">Hírek</a></span>
              </li>
            </ul>
          </div>
          <div className="col-xs-6 col-sm-4  footerMobile">
            <ul className="footerMenuList ">
              <li>
                <span><Link to="/">Kapcsolat felvétel</Link></span>
              </li>
              <li>
                {/* < <span><Link to="/">Hibabejelentés</Link></span> */}
              </li>
              <li>
                <span><Link to="/impress">Impresszum</Link></span>
              </li>
              <li>
                <span><Link to="/privacy">GDPR</Link></span>
              </li>
              <li>
                <span><Link to="/terms">ÁSZF</Link></span>
              </li>
              <li>
                <span><Link to="/cookie">Süti kezelő</Link></span>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-sm-4  col-xs-12 elerhetosegekCol">
            <div className="elerhetosegekWrapper">
              <h3>Elérhetőségeink</h3>
              <ul id="footerContactList">
                <li>
                  <img src={require('../../assets/images/ikon-kapcsolat.svg').default} alt='ikonkapcsolat' />
                  <span>+36-30/68-97-726</span>
                </li>
                <li>
                  <img src={require('../../assets/images/ikon-level.svg').default} alt='ikonlevel' />
                  <span>vasgarazs@gmail.com</span>
                </li>
                <li>
                  <img src={require('../../assets/images/ikon-map.svg').default} alt='ikonmap' />
                  <span>
                    1097 Budapest
                    <br /> Vaskapu u. 10-14.
                  </span>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="col-md-1 col-xs-12">
            <div className="row footerSocials">
              <div className="col-md-12 col-xs-4 text-center">
                <Link to="/" target="_blank">
                  <img src={require('../../assets/images/ikonFB.svg').default} alt='fb' />
                </Link>
              </div>
              <div className="col-md-12 col-xs-4 text-center">
                <Link to="/" target="_blank">
                  <img src={require('../../assets/images/ikonYT.svg').default} alt='yt' />
                </Link>
              </div>
              <div className="col-md-12 col-xs-4 text-center">
                <Link to="/" target="_blank">
                  <img src={require('../../assets/images/ikonIG.svg').default} alt='ig' />
                </Link>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="subFooter">
        <div className="subFooterContent">© 2022, Vasgarázs</div>
      </div>
    </footer>    
  );
};

export default Footer;