import { doc, getDoc } from "firebase/firestore";
import { db } from "../config/config";
import { IUser } from "../hooks/useFirebaseAuthentication";

const getUser = async (id: string) => {
    const docRef = doc(db, "users", id);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data() as IUser;
    return data;
}
export default getUser;