import React, { useState } from 'react';
import '../../../../styles/multiple_inputs.css';
import AddCompanyDetails from './add_company_details';
import AddPersonalDetails from './add_personal_details';


const AddUser: React.FC = () => {
    const [registrationType, setRegistrationType] = useState<'company' | 'person'>('person');

    const renderBody = () => {
        switch (registrationType) {
            case 'company':
                return <AddCompanyDetails registrationType={registrationType} />
            case 'person':
                return <AddPersonalDetails registrationType={registrationType} />
            default:
                return null;
        }
    }

    return (
        <div className="wrapper">
            <div className="context">
                <h1 className="admin_title">Új ügyfél</h1>
            </div>
            <label>Regisztrálás típus:</label>
            <br />
            <select value={registrationType} onChange={(v) => setRegistrationType(v.target.value as any )}>
                <option key="none" value={undefined}>-</option>
                <option key="person" value="person">Magánszemély</option>
                <option key="company" value="company">Cég</option>
            </select>
            <br />
            <br />
            {renderBody()}
            <br />
            <br />
        </div>
    );
}

export default AddUser;