import React, { useState } from 'react';
import arrowVertical from '../../../assets/images/arrowVertical.svg';

const Process = () => {
  const [step, setStep] = useState<0 | 1 | 2>(0);

  const step1 = () =>  (
      <section id="sectionMukodesi">
        <div className="titlePart">
          <h2>Működési folyamat</h2>
        </div>
        <div id="mukodesi" className="mukodesiDesktop">
          <div className="row">
            <div className="col-md-12 col-xs-3">
              <div id="lepesekwrapper">
                <div id="lepesek" className="row">
                  <div className="lepesBlock">
                    <img src={require('../../../assets/images/lepesek-1.svg').default} alt='lepesek1' className="lepesImage lepesImage1" />
                    <img src={arrowVertical} alt='arrowVertical' className="downArrow" />
                  </div>
                  <div className="lepesBlock">
                    <img src={require('../../../assets/images/lepesek-2.svg').default} alt='lepesek-2' className="lepesImage lepesImage2" />
                    <img src={arrowVertical} className="downArrow" alt='arrowVertical' />
                  </div>
                  <div className="lepesBlock">
                    <img src={require('../../../assets/images/lepesek-3.svg').default} alt='lepesek-3' className="lepesImage lepesImage3" />
                    <img src={arrowVertical} alt='arrowVertical' className="downArrow" />
                  </div>
                  <div className="lepesBlock">
                    <img src={require('../../../assets/images/lepesek-4.svg').default} alt='lepesek-4' className="lepesImage lepesImage4 inactive" />
                    <img src={arrowVertical} className="downArrow" alt='arrowVertical' />
                  </div>
                  <div className="lepesBlock">
                    <img src={require('../../../assets/images//lepesek-5.svg').default} alt='lepesek-5' className="lepesImage lepesImage5 inactive" />
                    <img src={arrowVertical} className="downArrow" alt='arrowVertical' />
                  </div>
                  <div className="lepesBlock">
                    <img src={require('../../../assets/images/lepesek-6.svg').default} alt='lepesek-6' className="lepesImage lepesImage6 inactive" />
                    <img src={arrowVertical} alt='arrowVertical' className="downArrow" />
                  </div>
                  <div className="lepesBlock">
                    <img src={require('../../../assets/images/lepesek-7.svg').default} alt='lepesek-7' className="lepesImage lepesImage7 inactive" />
                    <img src={arrowVertical} alt='arrowVertical' className="downArrow" />
                  </div>
                  <div className="lepesBlock">
                    <img src={require('../../../assets/images/lepesek-8.svg').default} alt='lepesek-8' className="lepesImage lepesImage8 inactive" />
                    <img src={arrowVertical} alt='arrowVertical' className="downArrow" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-xs-9">
              <div className="lepesekSlider">
                <div id="lepesekLeirasWrapper1" className="lepesekSlide">
                  <div className="egyLepes">
                    <img src={require('../../../assets/images/szam1.svg').default} alt='szam1' />
                    <h3>Regisztráció</h3>
                    <p>
                      A regisztráció során kérjük adja meg személyes adatait. A
                      szerződést a megadott adatok alapján készítjük el.
                    </p>
                  </div>
                  <div className="egyLepes">
                    <img src={require('../../../assets/images/szam2.svg').default} alt='szam2' />
                    <h3>Garázsbeálló kiválasztása</h3>
                    <p>
                      A regisztráció után, a térképen válassza ki az Önnek
                      szimpatikus, szabad helyet.
                    </p>
                  </div>
                  <div className="egyLepes">
                    <img src={require('../../../assets/images/szam3.svg').default} alt='szam3' />
                    <h3>Számlázási időszak kiválasztása</h3>
                    <p>Kérem jelölje meg a számlázási időszakot.</p>
                  </div>
                </div>
              </div>
              <div id="lepesekPointsWrapper">
                <div id="lepesekPoints">
                  <ul>
                    <li>
                      <div data-target={1} onClick={() => setStep(0)} className="point pointSarga" />
                    </li>
                    <li data-target={2}>
                      <div data-target={2} onClick={() => setStep(1)} className="point" />
                    </li>
                    <li data-target={3}>
                      <div data-target={3} onClick={() => setStep(2)} className="point" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mukodesiMobile">
          <div className="row">
            <div className="col-md-3 col-xs-3 text-center">
              <img src={require('../../../assets/images/mukodesiMobile.svg').default} alt='mukodesiMobile' />
            </div>
            <div className="col-md-9 col-xs-9 ">
              <div className="egyLepes">
                <img src={require('../../../assets/images/szam1.svg').default} alt='szam1' />
                <h3>Regisztráció</h3>
                <ul className="lepesekMobileList">
                  <li>
                    Regisztrációja során adja meg személyes adatait. A szerződést az
                    Ön által megadott adatok alapján készítjük el.
                  </li>
                </ul>
              </div>
              <div className="egyLepes">
                <img src={require('../../../assets/images/szam2.svg').default} alt='szam2' />
                <h3>Helyválasztás és szerződés</h3>
                <ul className="lepesekMobileList">
                  <li>
                    A regisztráció után a térképen válassza ki az Önnek szimpatikus,
                    szabad helyet
                  </li>
                  <li>
                    Jelölje meg a számlázási időszakot és a bérlés kezdetének
                    időpontját.
                  </li>
                  <li>A szerződést online aláírással tudja megerősíteni.</li>
                </ul>
              </div>
              <div className="egyLepes">
                <img src={require('../../../assets/images/szam3.svg').default} alt='szam3' />
                <h3>Fizetés és használat</h3>
                <ul className="lepesekMobileList">
                  <li>
                    A számla a billingo.hu számlakezelő szolgáltatáson keresztül
                    fizethető.
                  </li>
                  <li>
                    Ha az első számla alapján a fizetés megtörtént, a megadott
                    telefonszámon regisztrálni fogjuk a rendszerben és bejuthat a
                    garázs épületébe, elkezdheti használni a kívánt helyet.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <h2 className="joPark text-center">Jó Parkolást Kívánunk!</h2>
        </div>
      </section>
    );

  const step2 = () => (
      <section id="sectionMukodesi">
        <div className="titlePart">
          <h2>Működési folyamat</h2>
        </div>
        <div id="mukodesi" className="mukodesiDesktop">
          <div className="row">
            <div className="col-md-12 col-xs-3">
              <div id="lepesekwrapper">
                <div id="lepesek" className="row">
                  <div className="lepesBlock">
                    <img src={require('../../../assets/images/lepesek-1.svg').default} alt='lepesek1' className="lepesImage lepesImage1 inactive" />
                    <img src={arrowVertical} alt='arrowVertical' className="downArrow" />
                  </div>
                  <div className="lepesBlock">
                    <img src={require('../../../assets/images/lepesek-2.svg').default} alt='lepesek-2' className="lepesImage lepesImage2 inactive" />
                    <img src={arrowVertical} className="downArrow" alt='arrowVertical' />
                  </div>
                  <div className="lepesBlock">
                    <img src={require('../../../assets/images/lepesek-3.svg').default} alt='lepesek-3' className="lepesImage lepesImage3 inactive" />
                    <img src={arrowVertical} alt='arrowVertical' className="downArrow" />
                  </div>
                  <div className="lepesBlock">
                    <img src={require('../../../assets/images/lepesek-4.svg').default} alt='lepesek-4' className="lepesImage lepesImage4" />
                    <img src={arrowVertical} className="downArrow" alt='arrowVertical' />
                  </div>
                  <div className="lepesBlock">
                    <img src={require('../../../assets/images//lepesek-5.svg').default} alt='lepesek-5' className="lepesImage lepesImage5" />
                    <img src={arrowVertical} className="downArrow" alt='arrowVertical' />
                  </div>
                  <div className="lepesBlock">
                    <img src={require('../../../assets/images/lepesek-6.svg').default} alt='lepesek-6' className="lepesImage lepesImage6" />
                    <img src={arrowVertical} alt='arrowVertical' className="downArrow" />
                  </div>
                  <div className="lepesBlock">
                    <img src={require('../../../assets/images/lepesek-7.svg').default} alt='lepesek-7' className="lepesImage lepesImage7 inactive" />
                    <img src={arrowVertical} alt='arrowVertical' className="downArrow" />
                  </div>
                  <div className="lepesBlock">
                    <img src={require('../../../assets/images/lepesek-8.svg').default} alt='lepesek-8' className="lepesImage lepesImage8 inactive" />
                    <img src={arrowVertical} alt='arrowVertical' className="downArrow" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-xs-9">
              <div className="lepesekSlider">
                <div id="lepesekLeirasWrapper2" className="lepesekSlide">
                  <div className="egyLepes">
                    <img src={require('../../../assets/images/szam4.svg').default} alt='szam4' />
                    <h3>Bérlés kezdetének időpontja</h3>
                    <p>Kérlem jelölje meg a bérlés megkezdésének dátumát.</p>
                  </div>
                  <div className="egyLepes">
                    <img src={require('../../../assets/images/szam5.svg').default} alt='szam5' />
                    <h3>Szerződés aláírása</h3>
                    <p>Kérlem írja alá a szerződést elektronikusan.</p>
                  </div>
                  <div className="egyLepes">
                    <img src={require('../../../assets/images/szam6.svg').default} alt='szam6' />
                    <h3>Számla kiállítása</h3>
                    <p>
                      A szerződés aláírása után a számla a billingo.hu számlakezelő
                      szolgáltatáson keresztül készül.
                    </p>
                  </div>
                </div>
              </div>
              <div id="lepesekPointsWrapper">
                <div id="lepesekPoints">
                  <ul>
                    <li>
                      <div data-target={1} onClick={() => setStep(0)} className="point" />
                    </li>
                    <li data-target={2}>
                      <div data-target={2} onClick={() => setStep(1)} className="point pointSarga" />
                    </li>
                    <li data-target={3}>
                      <div data-target={3} onClick={() => setStep(2)} className="point" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mukodesiMobile">
          <div className="row">
            <div className="col-md-3 col-xs-3 text-center">
              <img src={require('../../../assets/images/mukodesiMobile.svg').default} alt='mukodesiMobile' />
            </div>
            <div className="col-md-9 col-xs-9 ">
              <div className="egyLepes">
                <img src={require('../../../assets/images/szam1.svg').default} alt='szam1' />
                <h3>Regisztráció</h3>
                <ul className="lepesekMobileList">
                  <li>
                    Regisztrációja során adja meg személyes adatait. A szerződést az
                    Ön által megadott adatok alapján készítjük el.
                  </li>
                </ul>
              </div>
              <div className="egyLepes">
                <img src={require('../../../assets/images/szam2.svg').default} alt='szam2' />
                <h3>Helyválasztás és szerződés</h3>
                <ul className="lepesekMobileList">
                  <li>
                    A regisztráció után a térképen válassza ki az Önnek szimpatikus,
                    szabad helyet
                  </li>
                  <li>
                    Jelölje meg a számlázási időszakot és a bérlés kezdetének
                    időpontját.
                  </li>
                  <li>A szerződést online aláírással tudja megerősíteni.</li>
                </ul>
              </div>
              <div className="egyLepes">
                <img src={require('../../../assets/images/szam3.svg').default} alt='szam3' />
                <h3>Fizetés és használat</h3>
                <ul className="lepesekMobileList">
                  <li>
                    A számla a billingo.hu számlakezelő szolgáltatáson keresztül
                    fizethető.
                  </li>
                  <li>
                    Ha az első számla alapján a fizetés megtörtént, a megadott
                    telefonszámon regisztrálni fogjuk a rendszerben és bejuthat a
                    garázs épületébe, elkezdheti használni a kívánt helyet.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <h2 className="joPark text-center">Jó Parkolást Kívánunk!</h2>
        </div>
      </section>
    );

  const step3 = () => (
      <section id="sectionMukodesi">
        <div className="titlePart">
          <h2>Működési folyamat</h2>
        </div>
        <div id="mukodesi" className="mukodesiDesktop">
          <div className="row">
            <div className="col-md-12 col-xs-3">
              <div id="lepesekwrapper">
                <div id="lepesek" className="row">
                  <div className="lepesBlock">
                    <img src={require('../../../assets/images/lepesek-1.svg').default} alt='lepesek1' className="lepesImage lepesImage1 inactive" />
                    <img src={arrowVertical} alt='arrowVertical' className="downArrow" />
                  </div>
                  <div className="lepesBlock">
                    <img src={require('../../../assets/images/lepesek-2.svg').default} alt='lepesek-2' className="lepesImage lepesImage2 inactive" />
                    <img src={arrowVertical} className="downArrow" alt='arrowVertical' />
                  </div>
                  <div className="lepesBlock">
                    <img src={require('../../../assets/images/lepesek-3.svg').default} alt='lepesek-3' className="lepesImage lepesImage3 inactive" />
                    <img src={arrowVertical} alt='arrowVertical' className="downArrow" />
                  </div>
                  <div className="lepesBlock">
                    <img src={require('../../../assets/images/lepesek-4.svg').default} alt='lepesek-4' className="lepesImage lepesImage4 inactive" />
                    <img src={arrowVertical} className="downArrow" alt='arrowVertical' />
                  </div>
                  <div className="lepesBlock">
                    <img src={require('../../../assets/images//lepesek-5.svg').default} alt='lepesek-5' className="lepesImage lepesImage5 inactive" />
                    <img src={arrowVertical} className="downArrow" alt='arrowVertical' />
                  </div>
                  <div className="lepesBlock">
                    <img src={require('../../../assets/images/lepesek-6.svg').default} alt='lepesek-6' className="lepesImage lepesImage6 inactive" />
                    <img src={arrowVertical} alt='arrowVertical' className="downArrow" />
                  </div>
                  <div className="lepesBlock">
                    <img src={require('../../../assets/images/lepesek-7.svg').default} alt='lepesek-7' className="lepesImage lepesImage7" />
                    <img src={arrowVertical} alt='arrowVertical' className="downArrow" />
                  </div>
                  <div className="lepesBlock">
                    <img src={require('../../../assets/images/lepesek-8.svg').default} alt='lepesek-8' className="lepesImage lepesImage8" />
                    <img src={arrowVertical} alt='arrowVertical' className="downArrow" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-xs-9">
              <div className="lepesekSlider">
                <div id="lepesekLeirasWrapper3" className="lepesekSlide">
                  <div className="egyLepes">
                    <img src={require('../../../assets/images/szam7.svg').default} alt='szam7' />
                    <h3>Fizetés</h3>
                    <p>
                      A számla alapján a fizetést banki átutalással teljesítheti.
                    </p>
                  </div>
                  <div className="egyLepes">
                    <img src={require('../../../assets/images/szam8.svg').default} alt='szam8' />
                    <h3>Használat</h3>
                    <p>
                      Az első számla fizetése után, a megadott telefonszámon
                      regisztráljuk Önt a rendszerben, ezután bejuthat a garázs
                      épületébe és használhatja a kiválasztott és immár bérelt
                      gépkocsibeállót.
                    </p>
                  </div>
                </div>
              </div>
              <div id="lepesekPointsWrapper">
                <div id="lepesekPoints">
                  <ul>
                    <li>
                      <div data-target={1} onClick={() => setStep(0)} className="point" />
                    </li>
                    <li data-target={2}>
                      <div data-target={2} onClick={() => setStep(1)} className="point" />
                    </li>
                    <li data-target={3}>
                      <div data-target={3} onClick={() => setStep(2)} className="point pointSarga" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mukodesiMobile">
          <div className="row">
            <div className="col-md-3 col-xs-3 text-center">
              <img src={require('../../../assets/images/mukodesiMobile.svg').default} alt='mukodesiMobile' />
            </div>
            <div className="col-md-9 col-xs-9 ">
              <div className="egyLepes">
                <img src={require('../../../assets/images/szam1.svg').default} alt='szam1' />
                <h3>Regisztráció</h3>
                <ul className="lepesekMobileList">
                  <li>
                    Regisztrációja során adja meg személyes adatait. A szerződést az
                    Ön által megadott adatok alapján készítjük el.
                  </li>
                </ul>
              </div>
              <div className="egyLepes">
                <img src={require('../../../assets/images/szam2.svg').default} alt='szam2' />
                <h3>Helyválasztás és szerződés</h3>
                <ul className="lepesekMobileList">
                  <li>
                    A regisztráció után a térképen válassza ki az Önnek szimpatikus,
                    szabad helyet
                  </li>
                  <li>
                    Jelölje meg a számlázási időszakot és a bérlés kezdetének
                    időpontját.
                  </li>
                  <li>A szerződést online aláírással tudja megerősíteni.</li>
                </ul>
              </div>
              <div className="egyLepes">
                <img src={require('../../../assets/images/szam3.svg').default} alt='szam3' />
                <h3>Fizetés és használat</h3>
                <ul className="lepesekMobileList">
                  <li>
                    A számla a billingo.hu számlakezelő szolgáltatáson keresztül
                    fizethető.
                  </li>
                  <li>
                    Ha az első számla alapján a fizetés megtörtént, a megadott
                    telefonszámon regisztrálni fogjuk a rendszerben és bejuthat a
                    garázs épületébe, elkezdheti használni a kívánt helyet.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <h2 className="joPark text-center">Jó Parkolást Kívánunk!</h2>
        </div>
      </section>
    );


    switch (step) {
      case 0:
        return step1();
      case 1:
        return step2();
      case 2:
        return step3();
      default:
        return step1();
    }
}

export default Process;