import React, { useContext } from 'react';
import Calendar from 'react-calendar'
import Header from '../../pages/header/header';
import Footer from '../../pages/footer/footer';
import Button from '../button/button';
import '../../pages/logout/logout.css';
import './rent_start.css';
import RentContext from '../../contexts/RentContext';
import 'react-calendar/dist/Calendar.css';


const StartRent: React.FC = () => {
  const { startOfRental, setStartOfRental, setStep } = useContext(RentContext);

  
  return (
      <>
        <Header />
          <div id="pageMain">
            <section id="sectionBerlesKezdet">
              <div className="titlePart titlePart2">
                <h2>Bérlés Kezdetének Időpontja</h2>
              </div>
              <div className="berlesKezdetWrapper">
                <div className="berlesTablaWrapper">
                  <div className="row">
                    <Calendar value={startOfRental} onChange={(e) => setStartOfRental} />
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-xs-6">
                        <Button className="standardButton adatCancel" onClick={() => setStep(2)}>Mégse</Button>
                    </div>
                    <div className="col-md-6 col-xs-6">
                        <Button className="standardButton tovabbButton" onClick={() => setStep(4)}>Tovább  3/5</Button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        <Footer />
      </>
    )};
export default StartRent;