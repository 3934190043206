import React from "react";
import { useParams } from "react-router-dom";
import useAxios from "axios-hooks";

import { API_ROOT } from "../../../../utils/constants";
import "../../../../styles/multiple_inputs.css";
import { IUser } from "../../../../hooks/useFirebaseAuthentication";
import EditCompanyDetails from "./edit_company_details";
import EditPersonalDetails from "./edit_personal_details";

const EditUser: React.FC = () => {
  const { id } = useParams();
  const [{ data: user, loading }, refetch] = useAxios<IUser>(`${API_ROOT}/get-user/${id}`);

  if (!user || loading) return <div>Adatok betöltése...</div>;

  const renderBody = () => {
    switch (user.registertypeof) {
        case 'company':
            return <EditCompanyDetails user={user} refetch={refetch} />
        case 'person':
            return <EditPersonalDetails user={user} refetch={refetch} />
        default:
            return null;
    }
}

  return (
    <div className="wrapper">
      <div className="context">
        <h1 className="admin_title">Új ügyfél</h1>
      </div>
      <br />
      {renderBody()}
      <br />
      <br />
    </div>
  );
};

export default EditUser;
