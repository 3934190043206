import React from 'react';
import '../logout/logout.css';
import Header from '../logout/header/header';
import Footer from '../logout/footer/footer';

const DataEdit = () => {
  return (
    <>
    <Header />
      <div id="pageMain">
        <section id="sectionSzerzodesAdatmodositas">
          <div className="szerzodesAdatmodositasWrapper">
            <div className="modositasWindow myWindow">
              <h2>Adat Módosítás</h2>
              <div id="formWrapper">
                <form>
                  <label>
                    <img src={require('../../assets/images/ikon-user-sarga.svg').default} alt='ikon-user-sarga' /> Név
                  </label>
                  <input className="vgInput" name="name" />
                  <label>
                    <img src={require('../../assets/images/ikon-map-fekete.svg').default} alt='ikon-map-fekete' /> Lakcím
                  </label>
                  <input className="vgInput" name="address" />
                  <label>
                    <img src={require('../../assets/images/ikon-id-sarga.svg').default} alt='ikon-id-sarga' /> Személyi igazolvány szám
                  </label>
                  <input className="vgInput" name="id" />
                  <div className="row">
                    <div className="col-md-6 col-xs-6">
                      <button type="submit" className="adatCancel">
                        Vissza
                      </button>
                    </div>
                    <div className="col-md-6 col-xs-6">
                      <button type="submit" className="adatRefresh">
                        Frissítés
                      </button>
                    </div>
                  </div>
                  <br />
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>  
    <Footer />
    </>  
  );
};

export default DataEdit;