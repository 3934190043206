import { collection, getDocs } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../config/config';

export interface Reservation {
  id: string;
  userId: string;
  garageId: string;
}

const useReservations = () => {
    const [list, setList] = useState<Reservation[]>([]);

    useEffect(() => {
        const getData = async () => {
          const querySnapshot = await getDocs(collection(db, "reservations"));
          const temp: Reservation[] = [];
          querySnapshot.forEach((doc) => {
            temp.push({ ...(doc.data() as any), id: doc.id });
          });
    
          setList(temp);
        };
    
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      return list;
}

export default useReservations;