import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ref, uploadString } from "firebase/storage";

import { toPng } from 'html-to-image';

import {
  collection,
  doc,
  setDoc,
  updateDoc,
} from "firebase/firestore";

import { storage } from '../../config/config';
import Header from "../../pages/header/header";
import Footer from "../../pages/footer/footer";
import Button from "../button/button";
import "../../pages/logout/logout.css";
import AuthContext from "../../contexts/AuthContext";
import { IUser } from "../../hooks/useFirebaseAuthentication";
import getUser from "../../utils/getUser";
import { db } from "../../config/config";
import RentContext from "../../contexts/RentContext";
import useGarages from "../../hooks/useGarages";


const Contract: React.FC = () => {
  const [showButton, setShowButton] = useState(true);
  const [updatedUser, setUser] = useState<IUser>();
  const { user, refetch } = useContext(AuthContext);
  const HTMLRef = useRef<HTMLDivElement>(null)
  const garages = useGarages();
  const {
    startOfRental,
    billingFrequency,
    selectedGarage,
    step,
    setBillingFrequency,
    setSelectedGarage,
    setStartOfRental,
    setStep,
  } = useContext(RentContext);

  const garage = garages.find((g) => g.id === selectedGarage)

  const navigate = useNavigate();

  useEffect(() => {
    const getUserNode = async () => {
      if (!user) return;
      const userNode = await getUser(user.uid);
      setUser(userNode);
    };
    getUserNode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const onSubmit = useCallback(async () => {
    if (!HTMLRef.current || !user || !selectedGarage) {
      return
    }

    const userRef = doc(db, "users", user.uid);
    await updateDoc(userRef, {
      startOfRental: startOfRental.getTime(),
      billingFrequency,
    });

    const newReservationRef = doc(collection(db, "reservations"));
    await setDoc(newReservationRef, {
      userId: user.uid,
      garageId: selectedGarage,
    });

    setSelectedGarage(undefined);
    setBillingFrequency('MONTHLY');
    setStartOfRental(new Date());

    const storageRef = ref(storage, 'contract/' + user.uid + '/' + Date.now());

    setShowButton(false);
    const base64 = await toPng(HTMLRef.current, { cacheBust: true, })
    await uploadString(storageRef, base64, 'data_url');

    const link = document.createElement('a');
    link.download = user.uid + '_' + Date.now();
    link.href = base64
    link.click()

    setShowButton(true);

    await refetch();
    navigate("/profile");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref])

  return (
    <>
      <Header />
      <div id="pageMain">
        <section id="sectionSzerzodes" ref={HTMLRef}>
          <div className="titlePart titlePart2">
            <h2>Bérleti szerződés</h2>
          </div>
          <div className="szerzodesWrapper">
            {garage?.contractype === "szerzodes1" ? (
              <p>
                amely létrejött egyrészről a Vasgarázs Ingatlanforgalmazó és
                Szolgáltató Korlátolt Felelősségű Társaság (székhelye: 1036 Budapest, 
                Galagonya utca 5. I. em., nyilvántartja a Fővárosi Törvényszék
                Cégbírósága, cégjegyzékszám: 01-09-685095, statisztikai számjel:
                11956204-6820-113-01, adószám: 11956204-1-41, képviseletében:
                Papi Gergely ügyvezető) mint bérbeadó – a továbbiakban: Bérbeadó –
              </p>
            ) : garage?.contractype === "szerzodes2" ? (
              <p>
                amely létrejött egyrészről a VasgarázsPlusz Korlátolt Felelősségű 
                Társaság (székhelye: 1152 Budapest, Rákos út 159/A, nyilvántartja a 
                Fővárosi Törvényszék Cégbírósága, cégjegyzékszám: 01-09-431127, 
                statisztikai számjel: 32568879-6820-113-01, adószám: 32568879-1-42, 
                képviseletében: Horváth Dóra ügyvezető) mint bérbeadó 
                – a továbbiakban: Bérbeadó –
              </p>
            ) : null}

            {updatedUser?.registertypeof === "person" ? (
              <p>
                másrészről <b>{updatedUser.name}</b> (lakcím:{" "}
                <b>{updatedUser.address}</b>
                személyi igazolvány szám: <b>{updatedUser.id_card}</b>) mint
                bérlő – a továbbiakban: Bérlő
              </p>
            ) : updatedUser?.registertypeof === "company" ? (
              <p>
                másrészről<b>{updatedUser.companyName}</b> (székhelye:
                <b>{updatedUser.address}</b> , cégjegyzékszám:{" "}
                <b>{updatedUser.taxNumber}</b>, nyilvántartja a{" "}
                <b>{updatedUser.registryOrganization}</b>, statisztikai
                számjel:
                <b>{updatedUser.statisticalNumber}</b>, adószám:{" "}
                <b>{updatedUser.taxNumber}</b>, képviseletében:
                <b>{updatedUser.representativeName}</b>) mint bérlő – a
                továbbiakban: Bérlő –
              </p>
            ) : null}

            <p>között az alulírott napon és helyen, az alábbi feltételekkel:</p>
            <ul>
              <li>
                1. A bérleti szerződés tárgya
                <ul>
                  <li>
                    1.1 A Bérbeadó kijelenti, hogy kizárólagos tulajdonát képezi
                    a 1097 Budapest, Vaskapu u. 10-14. sz. -2. szintjén
                    található,{" "}
                    {garages.find((g) => g.id === selectedGarage)?.name + " "}
                    számú épkocsi beálló – a továbbiakban: Bérlemény.
                  </li>
                  <li>
                    1.2 A Felek jelen szerződéssel megállapodnak, hogy a
                    Bérbeadó bérbeadja, a Bérbevevő bérbeveszi az 1.1 pontban
                    megjelölt Bérleményt.
                  </li>
                  <li>
                    1.3 A bérleti jogviszony{" "}
                    {startOfRental.toLocaleDateString()} napjától kezdődően,
                    határozatlan időre szól.
                  </li>
                  <li>
                    1.4 A Bérlő a bérleményt a bérleti jogviszony első napján
                    veszi birtokba.
                  </li>
                </ul>
              </li>
              <li>
                2. Bérleti díj és költségek
                <ul>
                  <li>2.1 A bérleti díj összege havi 23.000 Ft.</li>
                  <li>
                    2.2 A bérleti díjat a Bérlő havonta előre, minden tárgyhó
                    ötödik munkanapjáig köteles megfizetni a Bérbeadó Raiffeisen
                    Bank Zrt. 12001008-00146008-00100008 sz. bankszámlájára
                    történő átutalás útján, a Bérbeadó által kiállított számla
                    alapján.
                  </li>
                  <li>
                    2.3 A bérleti díj mértéke a bérleti szerződés megkötését
                    követő évtől a KSH által az előző évre közzétett infláció
                    mértékével emelhető.
                  </li>
                  <li>
                    2.4 Késedelmes fizetés esetén a Bérlő a Ptk.-ban a
                    gazdálkodó szervezetek egymás közötti jogviszonyaira
                    meghatározott mértékű késedelmi kamatot köteles fizetni a
                    Bérbeadó részére, a késedelem teljes időtartamára.
                  </li>
                  <li>
                    2.5 A Bérleményhez kapcsolódó minden költséget a Bérleti díj
                    tartalmazza.
                  </li>
                </ul>
              </li>
              <li>
                3. A Felek egyéb jogai és kötelezettségei
                <ul>
                  <li>
                    3.1 A Bérlő tudomásul veszi, hogy a Bérleményt
                    rendeltetésszerűen köteles használni.
                  </li>
                  <li>
                    3.2 A Bérbeadó a szerződésszerű használatot bármikor
                    jogosult ellenőrizni.
                  </li>
                  <li>
                    3.3 A Felek megállapodnak abban, hogy a Bérlemény bármilyen
                    átalakítására, felújítására a Bérlő csak a Bérbeadó előzetes
                    írásbeli engedélyével jogosult.
                  </li>
                  <li>
                    3.4 Bérlő a Bérleményt harmadik félnek csak a Bérbeadó
                    írásos hozzájárulásával adhatja használatba.
                  </li>
                </ul>
              </li>
              <li>
                4. A szerződés megszűnése
                <ul>
                  <li>
                    4.1 Jelen szerződést bármelyik félnek a másik félhez
                    intézett írásbeli nyilatkozatával, két hónapos felmondási
                    idővel rendes felmondással, indokolás nélkül jogosult
                    felmondani
                  </li>
                  <li>
                    4.2 A Bérbeadó a szerződést felmondhatja, ha
                    <ul>
                      <li>
                        4.2.1 a Bérlő a bérleti díjat a fizetésre megállapított
                        időpontig nem fizeti meg;
                      </li>
                      <li>
                        4.2.2 a Bérlő a szerződésben vállalt vagy jogszabályban
                        előírt egyéb lényeges kötelezettségét nem teljesíti;
                      </li>
                      <li>
                        4.2.3 a Bérlő a Bérbeadóval vagy a lakókkal, más
                        bérlőkkel szemben az együttélés szabályaival ellentétes,
                        botrányos, tűrhetetlen magatartást tanúsít;
                      </li>
                      <li>
                        4.2.4 a Bérlő a bérleményt, a közös használatra szolgáló
                        helyiségeket, területet rongálja vagy a rendeltetésükkel
                        ellentétesen használja.
                      </li>
                    </ul>
                  </li>
                  <li>
                    4.3 A bérleti jogviszony megszűnésekor a Bérlő köteles a
                    bérleményt rendeltetésszerű használatra alkalmas állapotban
                    visszaadni a Bérbeadó részére.
                  </li>
                  <li>
                    4.4 A felmondás gyakorlására a lakások és helyiségek
                    bérletére, valamint az elidegenítésükre vonatkozó egyes
                    szabályokról szóló 1993. évi LXXVIII. tv. – a továbbiakban:
                    Ltv. – rendelkezései az irányadóak.
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              Jelen szerződésben nem szabályozott kérdésekben az Ltv., a Polgári
              Törvénykönyvről szóló 2013. évi V. tv. és a vonatkozó egyéb
              jogszabályok rendelkezései az irányadóak.
            </p>
            <p>
              Ezt a megállapodást a Felek elolvasás és értelmezés után, mint
              akaratukkal mindenben megegyezőt, jóváhagyólag írják alá.
            </p>
            <p>
              <b>Budapest, {new Date().toLocaleDateString()}</b>
            </p>

            {step === 4 ? (
              <div className="szerzodesKetGomb">
                <Button
                  className="gombAdatokModositasa"
                  onClick={() => setStep(3)}
                >
                  Mégse
                </Button>
                <Button className="gombAlairas" onClick={() => setStep(5)}>
                  Aláírás
                </Button>
              </div>
            ) : step === 6 ? (
              <>
                <div className="szerzodesAlairasPart">
                  <div className="row">
                    <div className="col-xs-12 col-sm-6">
                      <div className="szerzodesAlairasLeft">
                        <div style={{ height: 100 }}></div>
                        <div className="signatureLine"></div>
                        <div className="signatureName">Vasgarázs Kft.</div>
                        <div className="signatureWho">Bérbeadó</div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-6">
                      <div className="szerzodesAlairasRight">
                        <img
                          style={{ height: 100 }}
                          alt="signature"
                          src={updatedUser?.signature}
                        />
                        <div className="signatureLine"></div>
                        <div className="signatureName">{updatedUser?.name}</div>
                        <div className="signatureWho">Bérlő</div>
                      </div>
                    </div>
                  </div>
                </div>
                {showButton && <div className="szerzodesKetGomb">
                  <Button className="gombAlairas" onClick={onSubmit}>
                    Kész
                  </Button>
                </div>}
              </>
            ) : null}
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Contract;
