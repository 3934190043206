import React, { useContext } from 'react';
import AuthContext from '../../contexts/AuthContext';
import '../../pages/logout/logout.css';
import useGarages from '../../hooks/useGarages';
import { getBillingFrequency } from '../../utils/helperFunctions';
import { useNavigate } from 'react-router-dom';

const CompanyProfile = () => {
  const { userNode } = useContext(AuthContext);
  const garages = useGarages();
  const navigate = useNavigate();

  return (
      <div id="pageMain">
        <section id="sectionProfil">
          <div id="profilWrapper">
            <div className="row">
              <div className="col-xs-12 col-md-3 text-center">
                <div className="profilePicWrapper">
                  <div className="profilePic">
                    <img src={require('../../assets/images/profilePicEmpty.svg').default} alt='profilePicEmpty' />
                  </div>
                </div>
                <div className="profileName">{userNode?.companyName}</div>
              </div>
              <div className="col-xs-12 col-md-3">
                <div className="ikonSzovegPart">
                  <div className="ikonSzovegIkon">
                    <img src={require('../../assets/images/ikon-rendszam.svg').default} alt='ikon-rendszam' />
                  </div>
                  <div className="ikonSzovegSzoveg">Rendszám</div>
                  <div className="ikonSzovegAdat bold">
                    {userNode?.plateNumbers.join(', ')}
                  </div>
                </div>
                <div className="ikonSzovegPart">
                  <div className="ikonSzovegIkon">
                    <img src={require('../../assets/images/ikon-garazs.svg').default} alt='ikon-garazs' />
                  </div>
                  <div className="ikonSzovegSzoveg">Garázs</div>
                  <div className="ikonSzovegAdat bold">
                    {userNode?.myReservations.map((my) => garages.find((g) => g.id === my)?.name).join(', ')}
                  </div>
                </div>
                <div className="ikonSzovegPart">
                  <div className="ikonSzovegIkon">
                    <img src={require('../../assets/images/ikon-magan.svg').default} alt='ikon-magan' />
                  </div>
                  <div className="ikonSzovegSzoveg">Képviselő</div>
                  <div className="ikonSzovegAdat">{userNode?.name}</div>
                </div>
              </div>
              <div className="col-xs-12 col-md-3">
                <div className="ikonSzovegPart">
                  <div className="ikonSzovegIkon">
                    <img src={require('../../assets/images/ikon-tel.svg').default} alt='ikon-tel' />
                  </div>
                  <div className="ikonSzovegSzoveg">Telefonszám</div>
                  <div className="ikonSzovegAdat">{userNode?.contactPhones?.join(', ')}</div>
                </div>
                <div className="ikonSzovegPart">
                  <div className="ikonSzovegIkon">
                    <img src={require('../../assets/images/ikon-intezmeny.svg').default} alt='ikon-intezmeny' />
                  </div>
                  <div className="ikonSzovegSzoveg">Nyilvántartó szervezet</div>
                  <div className="ikonSzovegAdat">{userNode?.registryOrganization}</div>
                </div>
                <div className="ikonSzovegPart">
                  <div className="ikonSzovegIkon">
                    <img src={require('../../assets/images/ikon-stat.svg').default} alt="ikon-stat" />
                  </div>
                  <div className="ikonSzovegSzoveg">Statisztikai számjel</div>
                  <div className="ikonSzovegAdat">{userNode?.statisticalNumber}</div>
                </div>
                <div className="ikonSzovegPart">
                  <div className="ikonSzovegIkon">
                    <img src={require('../../assets/images/ikon-adoszam.svg').default} alt='ikon-adoszam' />
                  </div>
                  <div className="ikonSzovegSzoveg">Adószám</div>
                  <div className="ikonSzovegAdat">{userNode?.taxNumber}</div>
                </div>
                <div className="ikonSzovegPart">
                  <div className="ikonSzovegIkon">
                    <img src={require('../../assets/images/ikon-map-fekete.svg').default} alt='ikon-map-fekete' />
                  </div>
                  <div className="ikonSzovegSzoveg">Székhely</div>
                  <div className="ikonSzovegAdat">
                    {userNode?.address}
                  </div>
                </div>
                <div className="ikonSzovegPart">
                  <div className="ikonSzovegIkon">
                    <img src={require('../../assets/images/ikon-123.svg').default} alt='ikon-123' />
                  </div>
                  <div className="ikonSzovegSzoveg">Cégjegyzék szám</div>
                  <div className="ikonSzovegAdat">{userNode?.registrationNumber}</div>
                </div>
              </div>
              <div className="col-xs-12 col-md-3">
                <div className="ikonSzovegPart">
                  <div className="ikonSzovegIkon">
                    <img src={require('../../assets/images/ikon-berles1.svg').default} alt='ikon-berles1' />
                  </div>
                  <div className="ikonSzovegSzoveg">Bérlés kezdete</div>
                  <div className="ikonSzovegAdat">{userNode?.startOfRental}</div>
                </div>
                <div className="ikonSzovegPart">
                  <div className="ikonSzovegIkon">
                    <img src={require('../../assets/images/ikon-utolso.svg').default} alt='ikon-utolso' />
                  </div>
                  <div className="ikonSzovegSzoveg">Utolsó fizetés</div>
                  <div className="ikonSzovegAdat">-</div>
                </div>
                <div className="ikonSzovegPart">
                  <div className="ikonSzovegIkon">
                    <img src={require('../../assets/images/ikon-fizetes1.svg').default} alt='ikon-fizetes1' />
                  </div>
                  <div className="ikonSzovegSzoveg">Fizetési intervallum</div>
                  <div className="ikonSzovegAdat">{getBillingFrequency(userNode?.billingFrequency)}</div>
                </div>
                <div className="ikonSzovegPart">
                  <div className="ikonSzovegIkon">
                    <img src={require('../../assets/images/ikon-fizetes2.svg').default} alt='ikon-fizetes2' />
                  </div>
                  <div className="ikonSzovegSzoveg">Esedékes fizetés</div>
                  <div className="ikonSzovegAdat">-</div>
                </div>
              </div>
              <div className="col-xs-12 col-md-3 profilIkonokCol">
                <div className="profilIkonok">
                  {/* <img src={require('../../assets/images/ikon-beallitasok.svg').default} alt='ikon-beallitasok' height="56px" width="56px"/> */}
                  <br />
                  <img onClick={() => navigate('/rent')} src={require('../../assets/images/ikon-haz.svg').default} alt='ikon-haz' height="56px" width="56px"/>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>  
  );
};

export default CompanyProfile;