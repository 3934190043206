import React from 'react';
import Button from '../button/button';

interface Props {
    reset: () => void;
}

const ContaSendMessagesctForm: React.FC<Props> = ({ reset }) => {
        return (
            <div id="pageMain">
                <section id="sectionKapcsolat">
                    <div className="titlePart">
                        <h2>Kapcsolatfelvétel</h2>
                    </div>
                    <div id="kapcsolatWrapper" className="kapcsolatSuccess">
                        <div className="kapcsolatText">
                            Köszönjük, hogy felvette velünk a kapcsolatot.
                            <br /> Igyekszünk minél hamarabb válaszolni. Írjon nekünk és hamarosan
                            válaszolunk.
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-md-12">
                                <div className="kapcsolatSuccessWindow">
                                    <Button className="standardButton" onClick={reset}>Új Üzenet</Button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-md-12">
                                <div className="kapcsolatMap">
                                    <img src={require('../../assets/images/map.jpg')} alt='map' />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }

    export default ContaSendMessagesctForm;