import { useEffect, useState } from "react";
import { auth, db } from "../config/config";
import { User } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import useReservations from "./useReservations";

export type BillingFrequency = "MONTHLY" | "QUARTERLY" | "HALFLY" | "YEARLY";

export interface IUser {
  address: string;
  email: string;
  password?: string;
  id_card: string;
  name: string;
  plateNumbers: string[];
  registertypeof: "person" | "company";
  role: "user" | "admin";
  timestamp: string;
  signature?: string;
  startOfRental: string;
  billingFrequency: BillingFrequency;
  contactPhones?: string[];
  myReservations: string[];

  companyName?: string;
  registrationNumber?: string;
  registryOrganization?: string;
  statisticalNumber?: string;
  taxNumber?: string;
  representativeName?: string;
}

const useFirebaseAuthentication = () => {
  const [authUser, setAuthUser] = useState<User | null>();
  const [userNode, setUserNode] = useState<IUser | null | undefined>(null);
  const [isLoading, setLoading] = useState(true);
  const reservations = useReservations();

  const setUserNodeFv = async (userId: string) => {
    const docRef = doc(db, "users", userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const myReservations = reservations.filter((r) => r.userId === userId).map((r) => r.garageId);
      setUserNode({ ...docSnap.data(), myReservations } as IUser);
    }
  };

  useEffect(() => {
    if (reservations.length && authUser) {
      setUserNodeFv(authUser.uid);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservations, authUser])

  const refetch = async () => {
    if (!authUser) return;
    await setUserNodeFv(authUser.uid);
  };

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      setAuthUser(user);
      if (!user) {
        setLoading(false);
        setUserNode(null);
        return;
      }

      await setUserNodeFv(user.uid);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { user: authUser, userNode, isLoading, refetch };
};

export default useFirebaseAuthentication;
