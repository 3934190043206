import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../logout/header/header';
import Footer from '../../logout/footer/footer';
import useNews from '../../../hooks/useNews';
import '../../logout/logout.css';

const NewsPage = () => {
  const { id } = useParams();
  const news = useNews();
  const selectedNews = news.find((n) => n.id === id);

  return (
    <>
      <Header />
      <div className='wrapper' style={{ backgroundColor: 'white', minHeight: 'calc(100vh - 500px)', textAlign: 'center' }}>
        <img src={selectedNews?.imageSrc} alt="newsImg" />
        <h1>{selectedNews?.name}</h1>
        <p>{selectedNews?.description}</p>
        <br />
        <br />
      </div>
      <Footer />
    </>
  );
};

export default NewsPage;
