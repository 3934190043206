import React, { useContext } from 'react';
import { Link } from 'react-scroll';
import { Link as Linkto } from 'react-router-dom';
import LoginContext from '../../../contexts/LoginContext';

const HomeFooter = () => {
  const { setSelectorType } = useContext(LoginContext);

  return (
    <footer id="mainFooter">
      <div className="footerWrapper">
        <div className="row">
          <div className="col-md-2 col-xs-12 col-sm-12 text-center">
            <Linkto to="/">
              <img src={require('../../../assets/images/logo.svg').default} alt='footerlogo' id="topLogo" />
            </Linkto>
          </div>
          <div className="col-md-3 col-xs-6 footerDesktop">
            <ul className="footerMenuList ">
              <li className="footerLoginLink">
                <img src={require('../../../assets/images/ikon-login2.svg').default} alt='ikonlogin2' className="headericon " />
                <span><Link to="sectionRegisztralj" spy={true} smooth={true} onClick={() => setSelectorType('login')}>Bejelentkezés</Link></span>
              </li>
              <li>
                <img src={require('../../../assets/images/ikon-rolunk.svg').default} alt='ikonrolunk' className="headericon" />
                <span><Link to="sectionTisztelt" spy={true} smooth={true}>Rólunk</Link></span>
              </li>
              <li>
                <img src={require('../../../assets/images/ikon-mukodesunk.svg').default} alt='ikonmukodesunk' className="headericon" />
                <span><Link to="sectionMukodesi" spy={true} smooth={true}>Működésünk</Link></span>
              </li>
              <li>
                <img src={require('../../../assets/images/ikon-berles.svg').default} alt='ikonberles' className="headericon" />
                <span><Link to="sectionFoglalas" spy={true} smooth={true}>Bérlés</Link></span>
              </li>
              <li>
                <img src={require('../../../assets/images/ikon-kapcsolat.svg').default} alt='ikonkapcsolat' className="headericon" />
                <Link to="sectionKapcsolat" spy={true} smooth={true}>Kapcsolat</Link>
              </li>
              <li>
                <img src={require('../../../assets/images/ikon-hirek.svg').default} alt='ikonhirek' className="headericon" />
                <span><a href="#sectionHirek">Hírek</a></span>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-xs-6 footerDesktop">
            <div className="footerMiddleLinks">
              {/* <Link to="/">Hibajelentés</Link> */}
              <br />
              <br />
              <Linkto to="/impress">Impresszum</Linkto>
              <Linkto to="/privacy">GDPR</Linkto>
              <Linkto to="/terms">ÁSZF</Linkto>
              <Linkto to="/cookie">Süti kezelő</Linkto>
            </div>
          </div>
          <div className="col-xs-6 col-sm-4 footerMobile">
            <ul className="footerMenuList ">
              <li className="footerLoginLink">
                <span><Link to="sectionRegisztralj" spy={true} smooth={true} onClick={() => setSelectorType('login')}>Bejelentkezés</Link></span>
              </li>
              <li>
                <span><Link to="sectionTisztelt" spy={true} smooth={true}>Rólunk</Link></span>
              </li>
              <li>
                <span><Link to="sectionMukodesi" spy={true} smooth={true}>Működésünk</Link></span>
              </li>
              <li>
                <span><Link to="sectionFoglalas" spy={true} smooth={true}>Bérlés</Link></span>
              </li>
              <li>
                <span><Link to="sectionKapcsolat" spy={true} smooth={true}>Kapcsolat</Link></span>
              </li>
              <li>
                <span><a href="#sectionHirek">Hírek</a></span>
              </li>
            </ul>
          </div>
          <div className="col-xs-6 col-sm-4  footerMobile">
            <ul className="footerMenuList ">
              <li>
                <span><Link to="/#sectionKapcsolat">Kapcsolat felvétel</Link></span>
              </li>
              <li>
                {/* < <span><Link to="/">Hibabejelentés</Link></span> */}
              </li>
              <li>
                <span><Linkto to="/impress">Impresszum</Linkto></span>
              </li>
              <li>
                <span><Linkto to="/privacy">GDPR</Linkto></span>
              </li>
              <li>
                <span><Linkto to="/terms">ÁSZF</Linkto></span>
              </li>
              <li>
                <span><Linkto to="/cookie">Süti kezelő</Linkto></span>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-sm-4  col-xs-12 elerhetosegekCol">
            <div className="elerhetosegekWrapper">
              <h3>Elérhetőségeink</h3>
              <ul id="footerContactList">
                <li>
                  <img src={require('../../../assets/images/ikon-kapcsolat.svg').default} alt='ikonkapcsolat' />
                  <span>+36-30/68-97-726</span>
                </li>
                <li>
                  <img src={require('../../../assets/images/ikon-level.svg').default} alt='ikonlevel' />
                  <span>vasgarazs@gmail.com</span>
                </li>
                <li>
                  <img src={require('../../../assets/images/ikon-map.svg').default} alt='ikonmap' />
                  <span>
                    1097 Budapest
                    <br /> Vaskapu u. 10-14.
                  </span>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="col-md-1 col-xs-12">
            <div className="row footerSocials">
              <div className="col-md-12 col-xs-4 text-center">
                <Linkto to="/" target="_blank">
                  <img src={require('../../../assets/images/ikonFB.svg').default} alt='fb' />
                </Linkto>
              </div>
              <div className="col-md-12 col-xs-4 text-center">
                <Linkto to="/" target="_blank">
                  <img src={require('../../../assets/images/ikonYT.svg').default} alt='yt' />
                </Linkto>
              </div>
              <div className="col-md-12 col-xs-4 text-center">
                <Linkto to="/" target="_blank">
                  <img src={require('../../../assets/images/ikonIG.svg').default} alt='ig' />
                </Linkto>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="subFooter">
        <div className="subFooterContent">© 2022, Vasgarázs</div>
      </div>
    </footer>    
  );
};

export default HomeFooter;