import React, { useContext } from 'react';
import Garage from '../../../components/garage/garage';
import Billing from '../../../components/billing/billing';
import StartRent from '../../../components/rent_start/rent_start';
import Contract from '../../../components/contract/contract';
import Signature from '../../../components/signature/signature';
import '../../logout/logout.css';
import RentContext from '../../../contexts/RentContext';


const Rent = () => {
    const { step } = useContext(RentContext);

        switch (step) {
            case 1:
                return <Garage />;
            case 2:
                return <Billing />;
            case 3:
                return <StartRent />;
            case 4:
                return <Contract />;
            case 5:
                return <Signature />;
            case 6:
                return <Contract />;
            default:
                return <Garage />;
            }
        };

export default Rent;