import React, { useContext } from 'react';
import AuthContext from '../../contexts/AuthContext';
import RentContext from '../../contexts/RentContext';
import useGarages from '../../hooks/useGarages';


const GarageItems = () => {
  const { userNode } = useContext(AuthContext);
  const garages = useGarages();
  const { selectedGarage, setSelectedGarage } = useContext(RentContext);


  return (
    <div id="myAbraWrapper2">
      <div className="abraWrapper" id="myAbraWrapper">
        <div className={`drGarazs drFelsosor ${garages.find((g) => g.name === "B201")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "B201")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "B201")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="b201" onClick={() => setSelectedGarage(garages.find((g) => g.name === "B201")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">B201</div>
            <div className="drTerulet">
              12,5 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drFelsosor ${garages.find((g) => g.name === "C243")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C243")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C243")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c243" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C243")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C243</div>
            <div className="drTerulet">
              12 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drFelsosor ${garages.find((g) => g.name === "C242")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C242")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C242")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c242" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C242")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C242</div>
            <div className="drTerulet">
              12 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drFelsosor ${garages.find((g) => g.name === "C241")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C241")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C241")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c241" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C241")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C241</div>
            <div className="drTerulet">
              12 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drFelsosor ${garages.find((g) => g.name === "C239")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C239")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C239")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c239" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C239")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C239</div>
            <div className="drTerulet">
              12 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drFelsosor ${garages.find((g) => g.name === "C238")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C238")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C238")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c238" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C238")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C238</div>
            <div className="drTerulet">
              12 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drFelsosor ${garages.find((g) => g.name === "C216")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C216")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C216")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c216" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C216")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C216</div>
            <div className="drTerulet">
              12 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drFelsosor ${garages.find((g) => g.name === "C215")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C215")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C215")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c215" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C215")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C215</div>
            <div className="drTerulet">
              12 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drFelsosor ${garages.find((g) => g.name === "C214")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C214")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C214")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c214" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C214")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C214</div>
            <div className="drTerulet">
              12 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drFelsosor ${garages.find((g) => g.name === "C213")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C213")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C213")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c213" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C213")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C213</div>
            <div className="drTerulet">
              12 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drFelsosor ${garages.find((g) => g.name === "C212")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C212")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C212")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c212" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C212")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C212</div>
            <div className="drTerulet">
              12 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drFelsosor ${garages.find((g) => g.name === "C211")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C211")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C211")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c211" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C211")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C211</div>
            <div className="drTerulet">
              12 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drFelsosor ${garages.find((g) => g.name === "C210")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C210")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C210")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c210" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C210")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C210</div>
            <div className="drTerulet">
              12 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drFelsosor ${garages.find((g) => g.name === "C209")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C209")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C209")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c209" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C209")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C209</div>
            <div className="drTerulet">
              12 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drFelsosor ${garages.find((g) => g.name === "C208")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C208")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C208")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c208" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C208")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C208</div>
            <div className="drTerulet">
              12 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drJobbsor ${garages.find((g) => g.name === "C207")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C207")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C207")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c207" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C207")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C207</div>
            <div className="drTerulet">
              12 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drJobbsor ${garages.find((g) => g.name === "C206")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C206")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C206")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c206" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C206")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C206</div>
            <div className="drTerulet">
              12 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drJobbsor ${garages.find((g) => g.name === "C205")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C205")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C205")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c205" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C205")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C205</div>
            <div className="drTerulet">
              12 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drJobbsor ${garages.find((g) => g.name === "C204")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C204")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C204")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c204" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C204")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C204</div>
            <div className="drTerulet">
              12 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drJobbsor ${garages.find((g) => g.name === "C203")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C203")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C203")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c203" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C203")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C203</div>
            <div className="drTerulet">
              12 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drJobbsor ${garages.find((g) => g.name === "C202")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C202")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C202")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c202" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C202")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C202</div>
            <div className="drTerulet">
              12 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drJobbsor ${garages.find((g) => g.name === "C201")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C201")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C201")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c201" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C201")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C201</div>
            <div className="drTerulet">
              12 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drBlokk4 ${garages.find((g) => g.name === "C221")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C221")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C221")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c221" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C221")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C221</div>
            <div className="drTerulet">
              12,6 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drBlokk4 ${garages.find((g) => g.name === "C220")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C220")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C220")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c220" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C220")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C220</div>
            <div className="drTerulet">
              12,6 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drBlokk4 ${garages.find((g) => g.name === "C219")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C219")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C219")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c219" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C219")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C219</div>
            <div className="drTerulet">
              12,6 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drBlokk4 ${garages.find((g) => g.name === "C218")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C218")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C218")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c218" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C218")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C218</div>
            <div className="drTerulet">
              12,6 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drBlokk4 ${garages.find((g) => g.name === "C217")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C217")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C217")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c217" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C217")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C217</div>
            <div className="drTerulet">
              12,6 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drBlokk4 ${garages.find((g) => g.name === "C223")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C223")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C223")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c223" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C223")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C223</div>
            <div className="drTerulet">
              12,6 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drBlokk4 ${garages.find((g) => g.name === "C222")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C222")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C222")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c222" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C222")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C222</div>
            <div className="drTerulet">
              12,6 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drAlso ${garages.find((g) => g.name === "C224")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C224")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C224")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c224" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C224")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C224</div>
            <div className="drTerulet">
              12,6 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drAlso ${garages.find((g) => g.name === "C225")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C225")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C225")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c225" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C225")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C225</div>
            <div className="drTerulet">
              12,6 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drAlso ${garages.find((g) => g.name === "C226")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C226")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C226")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c226" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C226")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C226</div>
            <div className="drTerulet">
              12,6 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drBlokk5 ${garages.find((g) => g.name === "C237")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C237")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C237")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c237" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C237")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C237</div>
            <div className="drTerulet">
              12,6 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drBlokk5 ${garages.find((g) => g.name === "C236")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C236")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C236")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c236" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C236")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C236</div>
            <div className="drTerulet">
              12,6 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drBlokk5 ${garages.find((g) => g.name === "C235")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C235")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C235")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c235" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C235")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C235</div>
            <div className="drTerulet">
              12,6 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drBlokk5 ${garages.find((g) => g.name === "C234")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C234")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C234")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c234" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C234")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C234</div>
            <div className="drTerulet">
              12,6 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drBlokk5 ${garages.find((g) => g.name === "C233")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C233")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C233")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c233" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C233")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C233</div>
            <div className="drTerulet">
              12,6 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drBlokk5 ${garages.find((g) => g.name === "C232")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C232")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C232")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c232" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C232")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C232</div>
            <div className="drTerulet">
              12,6 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drBlokk5 ${garages.find((g) => g.name === "C231")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C231")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C231")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c231" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C231")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C231</div>
            <div className="drTerulet">
              12,6 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drBlokk5 ${garages.find((g) => g.name === "C230")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C230")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C230")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c230" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C230")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C230</div>
            <div className="drTerulet">
              12,6 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drBlokk5 ${garages.find((g) => g.name === "C229")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C229")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C229")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c229" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C229")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C229</div>
            <div className="drTerulet">
              12,6 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drBlokk5 ${garages.find((g) => g.name === "C228")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C228")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C228")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c228" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C228")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C228</div>
            <div className="drTerulet">
              12,6 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drBlokk5 ${garages.find((g) => g.name === "C227")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "C227")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "C227")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="c227" onClick={() => setSelectedGarage(garages.find((g) => g.name === "C227")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">C227</div>
            <div className="drTerulet">
              12,6 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drBlokk6 ${garages.find((g) => g.name === "B211")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "B211")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "B211")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="b211" onClick={() => setSelectedGarage(garages.find((g) => g.name === "B211")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">B211</div>
            <div className="drTerulet">
              12,6 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drBlokk6 ${garages.find((g) => g.name === "B212")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "B212")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "B212")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="b212" onClick={() => setSelectedGarage(garages.find((g) => g.name === "B212")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">B212</div>
            <div className="drTerulet">
              12,6 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drBlokk6 ${garages.find((g) => g.name === "B213")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "B213")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "B213")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="b213" onClick={() => setSelectedGarage(garages.find((g) => g.name === "B213")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">B213</div>
            <div className="drTerulet">
              12,6 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drBlokk6 ${garages.find((g) => g.name === "B214")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "B214")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "B214")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="b214" onClick={() => setSelectedGarage(garages.find((g) => g.name === "B214")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">B214</div>
            <div className="drTerulet">
              12,6 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div className={`drGarazs drBlokk6 ${garages.find((g) => g.name === "B215")?.available ? '' : 'garazsFoglalt'} 
        ${garages.find((g) => g.name === "B215")?.id === selectedGarage ? 'valasztott' : ''} 
        ${userNode?.myReservations.includes(garages.find((g) => g.name === "B215")?.id || '') ? 'onGarazsa' : ''}
        `}
          id="b215" onClick={() => setSelectedGarage(garages.find((g) => g.name === "B215")?.id)}>
          <div className="drGarazsInner">
            <div className="drSorszam">B215</div>
            <div className="drTerulet">
              12,6 m<sup className="nm">2</sup>
            </div>
          </div>
        </div>
        <div id="drArrow">
          <img src={require('../../assets/images/arrow.svg').default} alt='arrow' />
        </div>
      </div>
    </div>
  )
};

export default GarageItems;