import React, { useContext, useState } from 'react';
import SignupCompanyStep1 from './signup_company_step1';
import SignupCompanyStep2 from './signup_company_step2';
import SignupCompanyStep3 from './signup_company_step3';
import SignupPersonalStep1 from './signup_person_step1';
import SignupPersonalStep2 from './signup_person_step2';
import ForgottenPwd from './forgotten_password';
import Login from './login';
import SignupCompanyContext from '../../../contexts/SignupCompanyContext';
import SignupPersonContext from '../../../contexts/SignupPersonContext';
import LoginContext from '../../../contexts/LoginContext';
import AuthContext from '../../../contexts/AuthContext';

const LoginSelection: React.FC = () => {
  const [registrationType, setRegistrationType] = useState<"company" | "person">();
  const { selectorType, setSelectorType } = useContext(LoginContext);
  const { state: companyState } = useContext(SignupCompanyContext);
  const { state: personState } = useContext(SignupPersonContext);
  const { userNode } = useContext(AuthContext);

  const renderSelector = () => {
    return (
      <section id="sectionRegisztralj">
        <div className="titlePart">
          <h2>Garázst bérelnél?</h2>
        </div>
        <div id="regisztralj">
          <div id="regisztraljFeherBack">
            <h3>Regisztráljon</h3>
            <p>és szerezze meg a megfelelő garázst.</p>
            <div id="regGombok">
              <button onClick={() => setSelectorType('signup')} id="regButton" className="active">Regisztráció</button>
              <button onClick={() => setSelectorType('login')} id="loginButton">Belépés</button>
            </div>
            <div className="nagyGombWrapper">
              <div onClick={() => setRegistrationType('company')} className="nagyGomb">
                <img src={require('../../../assets/images/cegikon.svg').default}  alt="Cég" className="tipusValaszto" />
                <h2>Cég</h2>
              </div>
              <div onClick={() => setRegistrationType('person')} className="nagyGomb">
                <img src={require('../../../assets/images/maganszemelyikon.svg').default}  className="tipusValaszto" alt="Magánszemély"/>
                <h2>Magánszemély</h2> 
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

    if (selectorType === "signup") { 
      if (registrationType === undefined) {
        return !userNode ? renderSelector() : null;
      } else if (registrationType === "company") {
        switch (companyState.step) {
          case 0:
            return <SignupCompanyStep1 setRegistrationType={setRegistrationType} />;
          case 1:
            return <SignupCompanyStep2 />;
          case 2:
              return <SignupCompanyStep3 />; 
          default:
            return <SignupCompanyStep1 setRegistrationType={setRegistrationType} />;
        }
      } else if (registrationType === "person") {
        switch (personState.step) {
          case 0:
            return <SignupPersonalStep1 setRegistrationType={setRegistrationType} />;
          case 1:
            return <SignupPersonalStep2 />;
          default:
            return <SignupPersonalStep1 setRegistrationType={setRegistrationType} />
        }
      }
    } else if (selectorType === "login") { 
        return  <Login />
    } else if (selectorType === "forgottenpwd") { 
      return  <ForgottenPwd />
    }   
    return <Login />
}

export default LoginSelection;
