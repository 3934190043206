import React from 'react';
import './logout.css';
/* import PopUpCookie from './popup/popup'; */
import About from './about/about';
import LoginSelection from './login/login_selection';
import Process from './process/process';
import LogoutRent from './rent/rent';
import Contact from './contact/contact';
// import News from './news/news';
import HomeHeader from './header/header';
import HomeFooter from './footer/footer';

const Logout = () => {
    return (
        <>
        <HomeHeader />
            <main id="pageMain">
                <About />
                { /* <PopUpCookie /> */ }
                <LoginSelection />
                <Process />
                <LogoutRent/>
                <Contact />
                {/* <News /> */}
            </main>
        <HomeFooter />
        </>
    );  
};

export default Logout;