import { useFormik } from "formik";
import React, { useContext } from "react";
import * as Yup from "yup";
import Button from "../../../components/button/button";
import Input from "../../../components/Input";
import SignupCompanyContext from "../../../contexts/SignupCompanyContext";

const schema = Yup.object({
  registryOrganization: Yup.string().required("Kötelező megadni"),
  statisticalNumber: Yup.string().required("Kötelező megadni"),
  taxNumber: Yup.string().required("Kötelező megadni"),
  representativeName: Yup.string().required("Kötelező megadni"),
});

const SignUpCompany2: React.FC = () => {
  const { state, dispatch } = useContext(SignupCompanyContext);

  const { values, handleSubmit, handleChange, errors } = useFormik({
    initialValues: {
      registryOrganization: state.registryOrganization,
      statisticalNumber: state.statisticalNumber,
      taxNumber: state.taxNumber,
      representativeName: state.representativeName,
    },
    validationSchema: schema,
    onSubmit: ({
      registryOrganization,
      statisticalNumber,
      taxNumber,
      representativeName,
    }) => {
      dispatch({
        registryOrganization,
        statisticalNumber,
        taxNumber,
        representativeName,
        step: 2,
      });
    },
  });

  return (
    <section id="sectionRegisztralj">
      <div className="titlePart">
        <h2>Garázst bérelnél?</h2>
      </div>
      <div id="regisztralj">
        <div id="regisztraljFeherBack" className="regisztracio">
          <h2 className="text-center">Regisztráció</h2>
          <div className="myWindow">
            <div id="formWrapper">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-xs-12 col-md-12">
                    <label>
                      <img
                        src={
                          require("../../../assets/images/ikon-intezmeny.svg")
                            .default
                        }
                        alt="ikon-intezmeny"
                      />{" "}
                      Nyilvántartó szervezete
                    </label>
                    <Input
                      id="registryOrganization"
                      value={values.registryOrganization}
                      handleChange={handleChange}
                      error={errors.registryOrganization}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-md-6">
                    <label>
                      <img
                        src={
                          require("../../../assets/images/ikon-szamjel.svg")
                            .default
                        }
                        alt="ikon-szamjel"
                      />{" "}
                      Statisztikai számjele
                    </label>
                    <Input
                      id="statisticalNumber"
                      value={values.statisticalNumber}
                      handleChange={handleChange}
                      error={errors.statisticalNumber}
                    />
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <label>
                      <img
                        src={
                          require("../../../assets/images/ikon-adoszam.svg")
                            .default
                        }
                        alt="ikon-adoszam"
                      />{" "}
                      Adószáma
                    </label>
                    <Input
                      style={{ width: '94%' }}
                      id="taxNumber"
                      value={values.taxNumber}
                      handleChange={handleChange}
                      error={errors.taxNumber}
                    />
                  </div>
                </div>
                <label>
                  <img
                    src={
                      require("../../../assets/images/ikon-kepviselo.svg")
                        .default
                    }
                    alt="ikon-kepviselo"
                  />{" "}
                  Képviselő neve
                </label>
                <Input
                  id="representativeName"
                  value={values.representativeName}
                  handleChange={handleChange}
                  error={errors.representativeName}
                />
                <div className="row">
                  <div className="col-xs-6 col-md-6">
                    <Button
                      className="adatCancel"
                      onClick={() => dispatch({ step: 0 })}
                    >
                      Vissza
                    </Button>
                  </div>
                  <div className="col-xs-6 col-md-6">
                    <Button disabled={!!Object.keys(errors).length} type="submit">
                      Tovább
                    </Button>
                  </div>
                </div>
                <div className="clearBoth" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SignUpCompany2;
