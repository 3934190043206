import React, { useContext } from 'react';
import Header from '../../pages/header/header';
import Footer from '../../pages/footer/footer';
import GarageItems from '../garage_items/garage_items';
import Button from '../button/button';
import '../../pages/logout/logout.css';
import RentContext from '../../contexts/RentContext';


const Garage: React.FC = () => {
    const { setStep, selectedGarage } = useContext(RentContext);
    
    return (
      <>
        <Header />
            <section id="sectionFoglalas">
                <div id="foglalasWrapper">
                    <div id="foglalasInner">
                        <div className="row">
                            <div className="col-xs-12 col-md-8" id="grid8AbraWrapper">
                                <GarageItems />
                            </div>
                            <div className="col-xs-12 col-md-4">
                                <div className="jeloleseinkWrapper">
                                    <h3 className="headingAraink">Áraink</h3>
                                    <div className="arainkAr">23.000 Ft</div>
                                    <h3 className="headingJeloleseink">Jelöléseink</h3>
                                    <div className="jelolesSor">
                                        <div className="jelolesCar">
                                            <img src={require('../../assets/images/car1.svg').default} alt='car1' />
                                        </div>
                                        Foglalt helyek
                                    </div>
                                    <div className="jelolesSor">
                                        <div className="jelolesCar">
                                            <img src={require('../../assets/images/car2.svg').default} alt='car2' />
                                        </div>
                                        Ön által választott hely
                                    </div>
                                    <div className="jelolesSor">
                                        <div className="jelolesCar">
                                            <img src={require('../../assets/images/car3.svg').default} alt='car3' />
                                        </div>
                                        Ön által használt garázs
                                    </div>
                                    <div className="jelolesSor">
                                        <div className="jelolesCar jelolesNyil">
                                            <img src={require('../../assets/images/arrow.svg').default} alt='arrow' />
                                        </div>
                                        Bejárat
                                    </div>
                                    <div className="garazsGombok">
                                        <Button disabled={!selectedGarage} className='standardButton tovabbButton' onClick={() => setStep(2)}>Tovább  1/5</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <Footer />
      </>
    )};

export default Garage