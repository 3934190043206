import React from 'react';
import '../../logout/logout.css';
import Header from '../../logout/header/header';
import Footer from '../../logout/footer/footer';

const PaySuccess = () => {
  return (
    <>
    <Header />
      <div id="pageMain">
        <section id="sectionUtalas">
          <div id="utalasWrapper" className="utalasSuccess">
            <h2>Az utalás megtörtént</h2>
            <div className="utalasText">Kellemes parkolást kívánunk.</div>
          </div>
        </section>
      </div>  
    <Footer />
    </>  
  );
};

export default PaySuccess;