import React from "react";
import { Link } from "react-scroll";
import { Link as Linkto } from 'react-router-dom';
import LoginLogout from "./loginout";

const HomeHeader = () => {
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        <header>
            <div id="toTheTop">
                <img onClick={goToTop} src={require('../../../assets/images/toTheTop.svg').default} alt="tothetop" />
            </div>
            <div id="mobileMenu">
                <ul className="mobileMenuList">
                    <li>
                        <Link to="sectionTisztelt" spy={true} smooth={true}>
                            <img src={require('../../../assets/images/ikon-rolunk.svg').default} className="headericon" alt='rolunkicon' />
                        </Link>
                    </li>
                    <li>
                        <Link to="sectionMukodesi" spy={true} smooth={true}>
                            <img src={require('../../../assets/images/ikon-mukodesunk.svg').default} className="headericon" alt='mukodesunkicon' />
                        </Link>
                    </li>
                    <li>
                        <Link to="sectionFoglalas" spy={true} smooth={true}>
                            <img src={require('../../../assets/images/ikon-berles.svg').default} className="headericon" alt='berlesicon' />
                        </Link>
                    </li>
                    <li>
                        <Link to="sectionKapcsolat" spy={true} smooth={true}>
                            <img src={require('../../../assets/images/ikon-kapcsolat.svg').default} className="headericon" alt='kapcsolaticon' />
                        </Link>
                    </li>
                    <li>
                        <Link to="sectionRegisztralj" spy={true} smooth={true}>
                            <img src={require('../../../assets/images/ikon-login2.svg').default} className="headericon" alt='login2icon' />
                        </Link>
                    </li>
                </ul>
            </div>
            <div id="sectionHeader">
                <div className="row" id="header">
                    <div className="col-xs-12">
                        <div className="box box-container">
                            <div className="row">
                                <div className="col-xs-1">
                                    <div className="box-first">
                                        <Linkto to="/" >
                                            <img src={require('../../../assets/images/logo.svg').default} alt='logoicon' className="" id="topLogo" />
                                        </Linkto>
                                    </div>
                                </div>
                                <div className="col-xs-8">
                                    <ul className="menu">
                                        <li>
                                            <img
                                                src={require('../../../assets/images/ikon-rolunk.svg').default} alt='rolunkicon' className="headericon" />
                                                <span><Link to="sectionTisztelt" spy={true} smooth={true}>Rólunk</Link></span>
                                        </li>
                                        <li>
                                            <img
                                                src={require('../../../assets/images/ikon-mukodesunk.svg').default} alt='mukodesunkicon'
                                                className="headericon"/>
                                                <span><Link to="sectionMukodesi" spy={true} smooth={true}>Működésünk</Link></span>
                                        </li>
                                        <li>
                                            <img
                                                src={require('../../../assets/images/ikon-berles.svg').default} alt='berlesicon' className="headericon" />
                                                <span><Link to="sectionFoglalas" spy={true} smooth={true}>Bérlés</Link></span>
                                        </li>
                                        <li>
                                            <img
                                                src={require('../../../assets/images/ikon-kapcsolat.svg').default} alt='kapcsolaticon' className="headericon" />
                                                <Link to="sectionKapcsolat" spy={true} smooth={true}>Kapcsolat</Link>
                                        </li>
                                    </ul>
                                </div>
                                <LoginLogout />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default HomeHeader;