import { useFormik } from "formik";
import React, { useContext } from "react";
import * as Yup from "yup";
import Button from "../../../components/button/button";
import Input from "../../../components/Input";
import SignupPersonContext from "../../../contexts/SignupPersonContext";

interface Props {
  setRegistrationType: (v: "company" | "person" | undefined) => void;
}

const schema = Yup.object({
  personalName: Yup.string().required("Kötelező megadni"),
  personalAddress: Yup.string().required("Kötelező megadni"),
  personalId: Yup.string().required("Kötelező megadni"),
});

const SignupPersonStep1: React.FC<Props> = ({ setRegistrationType }) => {
  const { state, dispatch } = useContext(SignupPersonContext);

  const { values, handleSubmit, handleChange, errors } = useFormik({
    initialValues: {
      personalName: state.personalName,
      personalAddress: state.personalAddress,
      personalId: state.personalId,
    },
    validationSchema: schema,
    onSubmit: ({ personalName, personalAddress, personalId }) => {
      dispatch({ personalName, personalAddress, personalId, step: 1 })
    },
  });

  return (
    <section id="sectionRegisztralj">
      <div className="titlePart">
        <h2>Garázst bérelnél?</h2>
      </div>
      <div id="regisztralj">
        <div id="regisztraljFeherBack" className="regisztracio">
          <h2 className="text-center">Regisztráció</h2>
          <div className="myWindow">
            <div id="formWrapper">
              <form onSubmit={handleSubmit}>
                <label>
                  <img
                    src={
                      require("../../../assets/images/maganszemelyikon.svg")
                        .default
                    }
                    alt="maganszemelyikon"
                  />{" "}
                  Név
                </label>
                <Input
                  id="personalName"
                  value={values.personalName}
                  handleChange={handleChange}
                  error={errors.personalName}
                />
                <label>
                  <img
                    src={
                      require("../../../assets/images/ikon-map-fekete.svg")
                        .default
                    }
                    alt="ikon-map-fekete"
                  />{" "}
                  Lakcím
                </label>
                <Input
                  id="personalAddress"
                  value={values.personalAddress}
                  handleChange={handleChange}
                  error={errors.personalAddress}
                />
                <label>
                  <img
                    src={
                      require("../../../assets/images/ikon-adoszam.svg").default
                    }
                    alt="ikon-adoszam"
                  />{" "}
                  Személyigazolvány szám
                </label>
                <Input
                  id="personalId"
                  value={values.personalId}
                  handleChange={handleChange}
                  error={errors.personalId}
                />
                <div className="row">
                  <div className="col-md-6 col-xs-6">
                    <Button
                      className="adatCancel"
                      onClick={() => setRegistrationType(undefined)}
                    >
                      Mégse
                    </Button>
                  </div>
                  <div className="col-md-6 col-xs-6">
                  <Button disabled={!!Object.keys(errors).length} type="submit">
                      Tovább
                  </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignupPersonStep1;
