import React from 'react';
import Footer from '../footer/footer';
import Header from '../header/header';
import '../logout/logout.css';


const Privacy = () => {
  return (
    <>
    <Header />
      <div id="pageMain">
        <section id="sectionSzerzodes" className=" adatkezelesWrapper">
          <h2 className="titlePart titlePart2  text-center">
            Adatkezelési Tájékoztató
          </h2>
          <h3 className="subTitle  text-center">Vasgarázs</h3>
          <div className="szerzodesWrapper text-left">
            <p>
              A Vasgarázs (a továbbiakban: „Adatkezelő” vagy „Társaság”) a természetes
              személyeknek a személyes adatok kezelése tekintetében történő védelméről
              és az ilyen adatok szabad áramlásáról, valamint a 95/46/EK rendelet
              hatályon kívül helyezéséről szóló, az Európai Parlament és a Tanács (EU)
              2016/679. számú rendeletben (a továbbiakban: GDPR) meghatározott
              tájékoztatási kötelezettségnek való megfelelése céljából az általa
              végzett valamennyi adatkezelési tevékenységre általánosan érvényes
              információkat a jelen tájékoztató keretében bocsátja az érintett
              rendelkezésére, függetlenül attól, hogy a személyes adatokat az
              érintettől gyűjtötte vagy nem tőle szerezte.
            </p>
            <h3>A Társaság által végzett adatkezelési tevékenység alapelvei</h3>
            Személyes adatok kezelését a Társaság jogszerűen, a tisztességes eljárás
            keretei között és átlátható módon végzi. <br /> Tisztességes és átlátható
            az adatkezelési eljárás, amennyiben az érintett könnyen hozzáférhető és
            közérthető módon megfelelő tájékoztatást kap arról, hogy a Társaság mely
            személyes adatait és hogyan gyűjti, illetve használja fel, milyen módon,
            mennyi ideig és melyik jogalap alapján kezeli, továbbá kik férhetnek
            azokhoz hozzá. A személyes adatok kezelésre kizárólag megfelelő jogalap
            megléte esetén kerül sor. <br /> A személyes adatokat a Társaság
            egyértelműen meghatározott és jogszerű célból, valamint ezekkel a célokkal
            összeegyeztethető módon kezeli úgy, hogy az adatkezelés a legszükségesebb
            mértékre korlátozódjon. Annak érdekében, hogy az adatkezelés pontos és
            naprakész legyen, a Társaság minden ésszerű intézkedést megtesz az
            adatkezelés célja szempontjából, pontatlan személyes adatok törlése vagy
            helyesbítése iránt. A kezelt személyes adatok tárolására olyan módon kerül
            sor, amely az érintettek azonosítását csak a cél eléréséhez szükséges
            mértékben teszi lehetővé. <br /> Az adatkezelés érdekében technikai vagy
            szervezési intézkedések alkalmazásával biztosítja a Társaság a személyes
            adatok megfelelő biztonságát, ideértve az adatok jogosulatlan vagy
            jogellenes kezelésével, véletlen elvesztésével, megsemmisítésével vagy
            károsodásával szembeni védelmet is.
            <br />
            <br />
            <h3>
              A Társaság alábbiakban felsorolt intézkedései hozzájárulnak az
              adatbiztonság megteremtéséhez:
            </h3>
            <ul className="pontosLista">
              <li>
                A felhasználói hozzáférések kezelése (fiókkezelés, hitelesítési
                információk, jogosultsági rendszer kialakítása).
              </li>
              <li>
                Logikai hozzáférés szabályozása (informatikai hozzáférési
                jogosultságok kezelése).
              </li>
              <li>
                Nyomon követhetőség (naplózás): a Társaság szabályzatai,
                rendszertervei leírják az információbiztonsági események naplózásának
                követelményeit, meghatározzák a hibák naplózását, a hibanaplók
                elemzését és a hibák elhárításának nyomon követését. A naplóelemzésre
                szoftveres támogatással kerül sor.
              </li>
              <li>Üzembiztonság. </li>
              <li>
                Rosszindulatú software-ek kiszűrése és telepítési korlátozások
                bevezetése: az egyes használt platformoktól és rendszerek fizikai
                szeparációjától függően a Társaság kialakította a vírusvédelmét,
                illetve a használható software-ek listáját. A felhasználók
                tudatosítása kiterjed a vírustámadásokra is.
              </li>
              <li>
                Operációs rendszer védelme (frissítések telepítése, naprakészen
                tartása).
              </li>
              <li>Webhely biztonsággal kapcsolatos intézkedések alkalmazása. </li>
              <li>Biztonsági mentés. </li>
              <li>Karbantartás (például: javítócsomagok telepítése). </li>
              <li>
                Hálózatbiztonság (tűzfalak, proxy-k, behatolás-védelmi eszközök).
              </li>
              <li>Titkosítás.</li>
              <li>Szegmentálás.</li>
              <li>Hardware biztonság. </li>
              <li>Felügyelet (szoftver). </li>
              <li>Szervezet garanciák, folyamatba épített ellenőrzés. </li>
              <li>Az adatokhoz való hozzáférés-rendszer és korlátozás. </li>
              <li>Adatvédelmi kockázatok felmérése, kezelése. </li>
              <li>
                Üzemeltetési eljárások és felelősségek meghatározása, dokumentált
                üzemeltetés, a fejlesztési és tesztelési környezetek elkülönítése.
              </li>
              <li>
                Információátviteli szabályozások és eljárások az információk védett és
                biztonságos közvetítéséért.
              </li>
              <li />
              <li />
              <li />
              <li />
            </ul>
            <h3>Az érintettet a személyes adatai kezelésével </h3>
            <h4>összefüggésben megillető jogok</h4>
            <p>
              A Társaság adatkezelési tevékenységével összefüggő - érintetti jogok
              gyakorlásával kapcsolatos - kérelmet vagy kérést indokolatlan késedelem
              nélkül, de mindenféleképpen a kérelem beérkezését követő legfeljebb egy
              hónapon belül teljesíti az Adatkezelő, kivéve, ha ez a határidő a
              kérelem összetettségére vagy a kérelmek számára tekintettel további két
              hónappal meghosszabbításra kerül. Az érintettet a Társaság adatkezelési
              tevékenységével összefüggésben az alábbi jogok illetik meg:
            </p>
            <h4>Hozzáféréshez való jog</h4>
            <ul className="pontosLista">
              <li>az adatkezelés célja(i); </li>
              <li>az adatkezeléssel érintett személyes adatok fajtái; </li>
              <li>
                a Felhasználó személyes adatainak továbbítása esetén az adattovábbítás
                jogalapja és címzettje(i);
              </li>
              <li>az adatkezelés tervezett időtartama; </li>
              <li>
                a Felhasználó jogai a személyes adatok helyesbítésével, törlésével és
                kezelésének korlátozásával, valamint a személyes adatok kezelése
                elleni tiltakozásával összefüggésben;
              </li>
              <li> a Hatósághoz való fordulás lehetősége; </li>
              <li>az adatok forrása; </li>
              <li>
                az adatfeldolgozók nevéről, címéről és az adatkezeléssel összefüggő
                tevékenységéről;
              </li>
            </ul>
            <p>
              <b>Helyesbítéshez való jog,</b> amelynek alapján az érintett jogosult
              arra, hogy kérésére a Társaság indokolatlan késedelem nélkül helyesbítse
              a rá vonatkozó pontatlan személyes adatokat, továbbá az adatkezelés
              céljának figyelembevételével jogosult arra, hogy kérje a hiányos
              személyes adatok kiegészítését.
            </p>
            <b> Kezelt adatok törlése</b> (az elfeledtetéshez való jog), amelynek
            alapján a Felhasználó kérelmezheti, hogy Adatkezelő indokolatlan késedelem
            nélkül törölje a rá vonatkozó személyes adatokat, az Adatkezelő pedig
            köteles arra, hogy az érintettre vonatkozó személyes adatokat indokolatlan
            késedelem nélkül törölje, ha az alábbi indokok valamelyike fennáll:
            <ul>
              <li>
                a személyes adatokra már nincs szükség abból a célból, amelyből azokat
                gyűjtötték vagy,
              </li>
              <li>a személyes adatokat más módon kezelték; </li>
              <li>
                a Felhasználó visszavonja hozzájárulását és az adatkezelésnek más
                jogalapja nincs;
              </li>
              <li>a Felhasználó tiltakozik személyes adatai kezelése miatt; </li>
              <li>a személyes adatok kezelése jogellenesen történt; </li>
              <li>
                a személyes adatokat az adatkezelőre alkalmazandó uniós vagy tagállami
                jogban előírt jogi kötelezettség teljesítéséhez törölni kell;
              </li>
              <li>
                a személyes adatok hozzájáruláson alapuló gyűjtésére az információs
                társadalommal összefüggő szolgáltatások gyermekek részére történő
                kínálásával kapcsolatban került sor.
              </li>
              <li />
            </ul>
            <p>
              Amennyiben az érintett olyan személyes adato(ka)t bocsát a Társaság
              rendelkezésére, amely(eket) az a konkrét adatkezelési céllal
              összefüggésben nem kért(ek), illetve ahhoz nem szükséges(ek), a Társaság
              a célszerűség elvével össze nem egyeztető adatokat – amennyiben az
              aránytalan terhet és költséget nem jelent – indokolással ellátva
              visszaküldi az érintettnek, vagy abban az esetben, amikor az adatok
              visszaküldése nem lehetséges, azokat törli vagy megsemmisíti.
            </p>
            <p>
              Adatkezelés korlátozásához való jog, amelynek alapján az érintett
              jogosult arra, hogy kérésére a Társaság korlátozza az adatkezelést,
              amennyiben az alábbiak valamelyike fennáll:
            </p>
            <ul className="pontosLista">
              <li>az érintett vitatja a személyes adatok pontosságát, </li>
              <li>
                az adatkezelés jogellenes és az érintett ellenzi az adatok törlését,
                ehelyett kéri azok felhasználásának korlátozását,
              </li>
              <li>
                a Társaságnak már nincs szüksége a személyes adatokra az adatkezelés
                céljából, azonban az érintett igényli azokat jogi igények
                előterjesztéséhez, érvényesítéséhez vagy védelméhez,
              </li>
              <li>
                az érintett tiltakozott az adatkezelés ellen, ez esetben a korlátozás
                arra az időtartamra vonatkozik, amíg megállapításra nem kerül, hogy a
                Társaság jogos indokai elsőbbséget élveznek-e az érintett jogos
                indokaival szemben.
              </li>
            </ul>
            <p>
              <b>Tiltakozáshoz való jog,</b> amelynek alapján az érintett jogosult
              arra, hogy a saját helyzetével kapcsolatos okokból bármikor tiltakozzon
              személyes adatainak jogos érdek alapján történő kezelése ellen, ideértve
              az említett rendelkezéseken alapuló profilalkotást is. Ebben az esetben
              a Társaság a személyes adatokat nem kezelheti tovább, kivéve, ha a
              Társaság bizonyítja, hogy az adatkezelést olyan kényszerítő erejű, jogos
              érdekek indokolják, amelyek elsőbbséget élveznek az érintett érdekeivel,
              jogaival és szabadságaival szemben, vagy amelyek jogi igények
              előterjesztéséhez, érvényesítéséhez vagy védelméhez kapcsolódnak.
            </p>
            <p>
              <b>Automatizált döntéshozatal esetén</b> (beleértve a profilalkotást is)
              az érintett jogosult arra, hogy ne terjedjen ki rá olyan, kizárólag
              automatizált adatkezelésen – ideértve a profilalkotást is – alapuló
              döntés, amely rá nézve joghatással járna vagy őt hasonlóképpen jelentős
              mértékben érintené.
            </p>
            <p>
              Automatizált döntéshozatal ellen történő tiltakozás abban az esetben nem
              lehetséges, ha a döntés:
            </p>
            <ul className="pontosLista">
              <li>
                az érintett és az Adatkezelő közötti szerződés megkötése vagy
                teljesítése érdekében szükséges,
              </li>
              <li>
                meghozatalát az Adatkezelőre alkalmazandó olyan jogszabály teszi
                lehetővé, amely az érintett jogainak és szabadságainak, valamint jogos
                érdekeinek védelmét szolgáló megfelelő intézkedéseket is megállapít,
              </li>
              <li>az érintett kifejezett hozzájárulásán alapul.</li>
            </ul>
            <h3>
              Az adatkezeléssel kapcsolatban az érintettet megillető jogérvényesítési
              lehetőségek
            </h3>
            <p>
              <b>
                Amennyiben sérelmesnek véli azt, ahogy a Társaság a személyes adatait
                kezeli, javasoljuk, hogy először intézzen megkeresést a Társaság jelen
                tájékoztató elején megadott elérhetőségei valamelyikén vagy forduljon
                a Társaság adatvédelmi tisztviselőjéhez.
              </b>
              Panaszát minden esetben megvizsgáljuk és mindent megteszünk azért, hogy
              megfelelően kezeljük azt. Ha panasza ellenére továbbra is sérelmezi,
              ahogy a Társaság kezeli a személyes adatait, vagy nem kíván nálunk
              panaszt tenni, az alábbi jogorvoslati lehetőségekkel élhet.
            </p>
            <h4>Felügyeleti hatóságnál történő panasztételhez való jog:</h4>
            <p>
              Amennyiben a Felhasználó úgy érzi, hogy megsértették a személyes adatok
              védelméhez való jogát, az irányadó jogszabályok szerint jogorvoslattal
              élhet a hatáskörrel rendelkező szerveknél.
            </p>
            <h3>Egyéb rendelkezések</h3>
            <p>
              Jelen Tájékoztatóra a magyar jog, különösen az információs
              önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII.
              törvény rendelkezései, valamint az Európai Parlament és a Tanács (EU) a
              természetes személyeknek a személyes adatok kezelése tekintetében
              történő védelméről és az ilyen adatok szabad áramlásáról, valamint a
              95/46/EK rendelet hatályon kívül helyezéséről szóló 2016/679 rendelete
              (2016. április 27.) irányadó.
            </p>
            <div className="text-right">Vasgarázs</div>
          </div>
        </section>
      </div>  
    <Footer />
    </>  
  );
};

export default Privacy;