import React, { useContext, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas'
import Header from '../../pages/header/header';
import Footer from '../../pages/footer/footer';
import Button from '../button/button';
import '../../pages/logout/logout.css'; 
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/config';
import AuthContext from '../../contexts/AuthContext';
import RentContext from '../../contexts/RentContext';


const Signature: React.FC = () => {
  const [ref, setRef] = useState<SignatureCanvas | null>(null);
  const { user } = useContext(AuthContext);
  const { setStep } = useContext(RentContext);

  const onSubmit = async () => {
    if (!ref || !user) return;

    const userRef = doc(db, "users", user.uid);
    await updateDoc(userRef, {
      signature: ref.toDataURL()
    });
    setStep(6);
  }
  
  return (
    <>
      <Header />
      <div id="pageMain">
        <section id="sectionSzerzodesAlairas">
          <div className="szerzodesAlairasWrapper">
            <h2>Aláírás</h2>
            <div className="alairasWindow">
              <div className="alairasText">
                Az alább mezőben helyezheti el aláírását.
              </div>
              <div className="alairasPaper bcPaint">
                <SignatureCanvas
                  ref={(ref) => setRef(ref)}
                  canvasProps={{ className: 'sigCanvas' }}

                />
              </div>
              <div className="alairasGombok">
                <div className="row">
                  <div className="col-xs-12 col-md-4" />
                  <div className="col-xs-6 col-md-4 text-center">
                    <Button className="standardButton noFloatButton buttonDisabled" onClick={() => setStep(4)}>Újra</Button>
                  </div>
                  <div className="col-xs-6 col-md-4">
                    <Button disabled={!ref || !user} className="standardButton signatureDoneButton" onClick={onSubmit}>Kész</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  )
};

export default Signature;