import { User } from 'firebase/auth';
import React, { createContext, ComponentType } from 'react';
import useFirebaseAuthentication, {
  IUser,
} from '../hooks/useFirebaseAuthentication';

const AuthContext = createContext<{
  user: User | null;
  userNode: IUser | null | undefined;
  refetch: () => Promise<void>;
  isLoading: boolean;
    }>({ user: null, userNode: null, isLoading: true, refetch: async () => undefined });

export const withAuthContext = (Component: ComponentType) => () => {
  const { user, userNode, isLoading, refetch } = useFirebaseAuthentication();

  if (user === undefined) return <p>loading ...</p>;
  return (
    <AuthContext.Provider
      value={{ user, userNode, isLoading, refetch }}
    >
      <Component />
    </AuthContext.Provider>
  );
};

export default AuthContext;
