import { collection, getDocs } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../config/config';
import useReservations, { Reservation } from './useReservations';

interface Garage {
  id: string;
  name: string;
  m2: number;
  available: boolean;
  contractype: string;
}

const useGarages = () => {
    const [list, setList] = useState<Garage[]>([]);
    const reservations = useReservations();

    useEffect(() => {
        const getData = async () => {
          const querySnapshotReservations = await getDocs(collection(db, "reservations"));
          const tempReservations: Reservation[] = [];
          querySnapshotReservations.forEach((doc) => {
            tempReservations.push({ ...doc.data() as any });
          });

          const querySnapshotGarages = await getDocs(collection(db, "garages"));
          const tempGarages: Garage[] = [];
          querySnapshotGarages.forEach((doc) => {
            tempGarages.push({ ...doc.data() as any, id: doc.id, available: !reservations.find((res) => res.garageId === doc.id) });
          });
          
          setList(tempGarages);
        };
    
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [reservations.length]);

      return list;
}

export default useGarages;