import React from 'react';
import Footer from '../footer/footer';
import Header from '../header/header';
import '../logout/logout.css';


const Impress = () => {
  return (
    <>
    <Header />
      <div id="pageMain">
        <section id="sectionImpresszum">
          <div id="impresszumWrapper">
            <div className="row">
              <div className="col-xs-12 col-md-12 text-center">
                <h2 className="titlePart titlePart2">Impresszum</h2>
                <h3 className="subTitle">Vasgarázs</h3>
                <div className="impresszumContent">
                  Kapcsolattartók: Papi Gergely
                  <br />
                  <br /> Székhely/levelezési cím: 1036 Budapest, Galagonya u.
                  <br />
                  <br /> E-mail cím: vasgarazs@gmail.com
                  <br />
                  <br /> Telefonszám: +36/306897726
                  <br />
                  <br /> Adószám: 11956204-2-41
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>  
    <Footer />
    </>  
  );
};

export default Impress;