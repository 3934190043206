import React, { useState } from 'react';
import Button from '../button/button';
import TextBox from '../textbox/textbox';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";

interface Props {
  name: string;
  email: string;
  subject: string;
  message: string;
  setName: (v: string) => void;
  setEmail: (v: string) => void;
  setSubject: (v: string) => void;
  setMessage: (v: string) => void;
  setStateType: (v: "" | "successful") => void;
}

const ContactForm: React.FC<Props> = ({
  name, email, subject, message, setName, setEmail, setSubject, setMessage, setStateType
}) => {
  const [verified, setVerified] = useState(false);

  const sendData = {
    name,
    email,
    subject,
    message
  };

  const sendEmail = () => {
    if (!verified) return null;
    emailjs.send('gmail_test', 'contact_test', sendData, 'nHy1g4piKjL_aPyGg')
      .then((result) => {
        setStateType("successful");
      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <section id="sectionKapcsolat">
      <div className="titlePart">
        <h2>Kapcsolatfelvétel</h2>
      </div>
      <div id="kapcsolatWrapper">
        <div className="kapcsolatText">
          Kérdése van?
          <br /> Írjon nekünk és hamarosan válaszolunk.
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-8">
            <div id="formWrapper">
              <form>
                <label>
                  <img src={require('../../assets/images/ikon-user-sarga.svg').default} alt='ikon-user-sarga' /> Név
                </label>
                <TextBox value={name} onChange={setName} type="text" className="vgInput" name="name" />
                <label>
                  <img src={require('../../assets/images/ikon-level-sarga.svg').default} alt='ikon-level-fekete' /> Email cím
                </label>
                <TextBox value={email} onChange={setEmail} type="email" className="vgInput" name="email" />
                <label>
                  <img src={require('../../assets/images/ikon-targy-sarga.svg').default} alt='ikon-targy-sarga' /> Tárgy
                </label>
                <select className="vgInput" value={subject} onChange={(e) => setSubject(e.target.value)}>
                  <option value="Kapcsolatfelvétel">Kapcsolatfelvétel</option>
                  <option value="Hibabejelentés">Hibabejelentés</option>
                </select>
                <label>
                  <img src={require('../../assets/images/ikon-question.svg').default} alt='ikon-question' /> Üzenet
                </label>
                <textarea className="vgInput" name="message" value={message} onChange={(e) => setMessage(e.target.value)} />
                <ReCAPTCHA
                  sitekey="6LeXD9YhAAAAAGpESd3aduCgD2eV8ysmpOyazOkQ"
                  onChange={(e) => e && setVerified(true)}
                />
                <Button disabled={!verified} onClick={sendEmail}>KÜLDÉS</Button>
              </form>
            </div>
          </div>
          <div className="col-xs-12 col-md-4 text-center">
            <div id="elerhetosegeinkWrapper">
              ELÉRHETŐSÉGEINK
              <br />
              <ul id="contactList">
                <li>
                  <img src={require('../../assets/images/elerhetosegek-telefon.svg').default} alt='elerhetosegek-telefon' />
                  +36-30/68-97-726
                </li>
                <li>
                  <img src={require('../../assets/images/elerhetosegek-level.svg').default} alt='elerhetosegek-level' />
                  vasgarazs@gmail.com
                </li>
                <li>
                  <img src={require('../../assets/images/elerhetosegek-map.svg').default} alt='elerhetosegek-map' /> 1097 Budapest
                  <br /> Vaskapu u. 10-14.
                </li>
              </ul>
            </div>
          </div>
          <iframe
            title="vaskapu"
            src="https://maps.google.com/maps?q=1097%20Budapest%20Vaskapu%20u.%2010-14.&t=&z=13&ie=UTF8&iwloc=&output=embed"
            width="100%"
            height="450"
            frameBorder="0"
            style={{ border: 0, margin: 40 }}
            allowFullScreen
            aria-hidden="false"
            tabIndex={0}
          />
        </div>
      </div>
    </section>
  );
}

export default ContactForm;
