import React, { useContext } from 'react';
import Header from '../../pages/header/header';
import Footer from '../../pages/footer/footer';
import Button from '../button/button';
import '../../pages/logout/logout.css';
import RentContext from '../../contexts/RentContext';


const Billing: React.FC = () => {
  const { billingFrequency, setBillingFrequency, setStep } = useContext(RentContext);
  
  return (
      <>
        <Header />
          <div id="pageMain">
            <section id="sectionSzamlazasi">
              <div className="titlePart titlePart2">
                <h2>Számlázási Időszak</h2>
              </div>
              <div className="szamlazasiIdoszakWrapper">
                <div className="row">
                  <div className="col-xs-12 col-md-3" onClick={() => setBillingFrequency('MONTHLY')} style={{ cursor: 'pointer', paddingTop: 6, borderRadius: 20, border: billingFrequency === 'MONTHLY' ? '10px solid #1b971b' : 'none' }}>
                    <div className="szIdWrapper">
                      <img src={require('../../assets/images/szId1.svg').default} alt='szId1' />
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-3" onClick={() => setBillingFrequency('QUARTERLY')} style={{ cursor: 'pointer', paddingTop: 6, borderRadius: 20, border: billingFrequency === 'QUARTERLY' ? '10px solid #1b971b' : 'none' }}>
                    <div className="szIdWrapper">
                      <img src={require('../../assets/images/szId2.svg').default} alt='szId2' />
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-3" onClick={() => setBillingFrequency('HALFLY')} style={{ cursor: 'pointer', paddingTop: 6, borderRadius: 20, border: billingFrequency === 'HALFLY' ? '10px solid #1b971b' : 'none' }}>
                    <div className="szIdWrapper">
                      <img src={require('../../assets/images/szId3.svg').default} alt='szId3' />
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-3" onClick={() => setBillingFrequency('YEARLY')} style={{ cursor: 'pointer', paddingTop: 6, borderRadius: 20, border: billingFrequency === 'YEARLY' ? '10px solid #1b971b' : 'none' }}>
                    <div className="szIdWrapper">
                      <img src={require('../../assets/images/szId4.svg').default} alt='szId4' />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-xs-6">
                      <Button className="standardButton adatCancel" onClick={() => setStep(1)}>Mégse</Button>
                  </div>
                  <div className="col-md-6 col-xs-6">
                      <Button className="standardButton tovabbButton" onClick={() => setStep(3)}>Tovább  2/5</Button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        <Footer />
      </>
    )};

export default Billing;