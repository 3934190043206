import React from "react";
import IHtmlAttributes from "../htmlattributes";

interface IButtonProps extends IHtmlAttributes {
    value?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    className?: string;
    alt?: string;
    name?: string;
    children?: React.ReactNode;
    type?: "button" | "submit" | "reset" | undefined;
}

const Button = ({
    className,
    type = "button",
    onClick,
    children,
    disabled,
  }: IButtonProps) => (
    <button style={{ background: disabled ? '#eee5b7' : '#ffd700', cursor: disabled ? 'auto' : 'pointer' }} disabled={disabled} className={className} type={type} onClick={onClick}>
      {children}
    </button>
  );
  
  export default Button;