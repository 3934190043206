import React from 'react';
  
const About = () => {
  return (
    <section id="sectionTisztelt">
      <div className="row" id="tiszteltPart">
        <div className="col-xs-12 col-md-6">
          <article>
            <h2>Tisztelt Érdeklődő!</h2>
            <div id="tiszteltTextBox">
              <p>
                Cégünk, a VasGarázs Kft. a 1097 Budapest, Vaskapu utca 10--14.
                címen, az épület -2. szintjén,
              </p>
              <p>
                50 garázzsal és négy tárolóhellyel rendelkezik, melyek bérbe
                adásával 15 éve áll ügyfelei szolgálatában.
              </p>
              <p>
                Biztonságos, száraz és fedett, rendszeresen ellenőrzött,
                takarított menedéket nyújtunk ügyfeleink gépjárműinek.
              </p>
              <p>
                Budapest egyre jobban fejlődő, frekventált részén, egyszerű ki- és
                behajtással, korrekt árakkal és megbízható, gyors ügykezeléssel
                várjuk érdeklődését!
              </p>
            </div>
          </article>
        </div>
        <div className="col-xs-12 col-md-6 topRightWrapper">
          <img src={require('../../../assets/images/tiszteltImage.jpg')} id="tiszteltImage" alt='tiszteltImage' />
          <div className="mobileRightImage" id="mobileRightImage">
            <img src= {require('../../../assets/images/tiszteltBack2.jpg?a=4543')} alt='tiszteltBack2'className="tiszteltImage"/>
          </div>
        </div>
      </div>
    </section>
  );
};
export default About;
