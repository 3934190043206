import React, { useContext } from 'react';
import { auth } from '../../../config/config';
import { Link } from "react-scroll";
import { Link as Linkto } from 'react-router-dom';
import {  signOut } from "firebase/auth";
import LoginContext from '../../../contexts/LoginContext';

const LoginLogout = () => {
  const { setSelectorType } = useContext(LoginContext);

  const Login = () => {
    return (
        <div className="col-xs-3" id="loginitem">
            <div className="box-nested">
                <img src={require('../../../assets/images/ikon-login2.svg').default} className="headericon" alt='login2icon' />
                <span><Link to="sectionRegisztralj" spy={true} smooth={true} onClick={() => setSelectorType('login')}>Belépés</Link></span>
            </div>
        </div>
    );
  }

  const Logout = () => {
    const logout = async () => {
      signOut(auth)
          .then((response) => {
            localStorage.removeItem('myPage.expectSignIn');
            window.location.reload();
          });
     };
    return (
        <div className="col-xs-3" id="loginitem">
            <div className="box-nested">
                <img src={require('../../../assets/images/ikon-login2.svg').default} className="headericon" alt='login2icon' />
                <span><Linkto to="/" onClick={logout}>Kilépés</Linkto></span>
            </div>
        </div>
    );
  }

    if (localStorage.getItem('myPage.expectSignIn')) { 
        return Logout();
    } else {
        return Login();
    }
  }
export default LoginLogout;
