import { collection, getDocs } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../config/config';

interface News {
  id: string;
  name: string;
  description: string;
  imageSrc: string;
}

const useNews = () => {
    const [list, setList] = useState<News[]>([]);

    useEffect(() => {
        const getData = async () => {
          const querySnapshot = await getDocs(collection(db, "news"));
          const temp: News[] = [];
          querySnapshot.forEach((doc) => {
            temp.push({ ...(doc.data() as any), id: doc.id });
          });
    
          setList(temp);
        };
    
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      return list;
}

export default useNews;