import React, { useContext } from 'react';
import { auth } from '../../config/config';
import {  signOut } from "firebase/auth";
import { Link } from 'react-router-dom';
import LoginContext from '../../contexts/LoginContext';

const LoginLogout = () => {
  const { setSelectorType } = useContext(LoginContext);

  const Login = () => (
        <div className="col-xs-3" id="loginitem">
            <div className="box-nested">
                <img src={require('../../assets/images/ikon-login2.svg').default} className="headericon" alt='login2icon' />
                <span><Link to="/" onClick={() => setSelectorType('login')}>Belépés</Link></span>
            </div>
        </div>
    );

  const Logout = () => {
    const logout = async () => {
      signOut(auth)
          .then((response) => {
            localStorage.removeItem('myPage.expectSignIn');
            window.location.reload();
          });
     };
    return (
        <div className="col-xs-3" id="loginitem">
            <div className="box-nested">
                <img src={require('../../assets/images/ikon-login2.svg').default} className="headericon" alt='login2icon' />
                <span><Link to="/" onClick={logout}>Kilépés</Link></span>
            </div>
        </div>
    );
  }

    if (localStorage.getItem('myPage.expectSignIn')) { 
        return Logout();
    } else {
        return Login();
    }
}

export default LoginLogout;