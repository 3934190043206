import React, { useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../../../components/button/button";
import SignupCompanyContext from "../../../contexts/SignupCompanyContext";
import Input from "../../../components/Input";

interface Props {
  setRegistrationType: (v: "company" | "person" | undefined) => void;
}

const signUpSchema = Yup.object({
  companyName: Yup.string()
    .min(2, "Túl rövid")
    .max(50, "Túl hosszú")
    .required("Kötelező megadni"),
  address: Yup.string().required("Kötelező megadni"),
  registrationNumber: Yup.string().required("Kötelező megadni"),
});

const SignUpCompany1: React.FC<Props> = ({ setRegistrationType }) => {
  const { state, dispatch } = useContext(SignupCompanyContext);

  const { values, handleSubmit, handleChange, errors } = useFormik({
    initialValues: {
      companyName: state.companyName,
      address: state.address,
      registrationNumber: state.registrationNumber,
    },
    validationSchema: signUpSchema,
    onSubmit: ({ companyName, address, registrationNumber }) => {
      dispatch({ companyName, address, registrationNumber, step: 1 })
    },
  });

  return (
    <section id="sectionRegisztralj">
      <div className="titlePart">
        <h2>Garázst bérelnél?</h2>
      </div>
      <div id="regisztralj">
        <div id="regisztraljFeherBack" className="regisztracio">
          <h2 className="text-center">Regisztráció</h2>
          <div className="myWindow">
            <div id="formWrapper">
              <form onSubmit={handleSubmit}>
                <label>
                  <img
                    src={
                      require("../../../assets/images/ikon-cegneve.svg").default
                    }
                    alt="ikon-cegneve"
                  />{" "}
                  Cég Neve
                </label>
                <Input
                  id="companyName"
                  value={values.companyName}
                  handleChange={handleChange}
                  error={errors.companyName}
                />
                <label>
                  <img
                    src={
                      require("../../../assets/images/ikon-map-fekete.svg")
                        .default
                    }
                    alt="ikon-map-fekete"
                  />{" "}
                  Székhelye
                </label>
                <Input
                  id="address"
                  value={values.address}
                  handleChange={handleChange}
                  error={errors.address}
                />

                <label>
                  <img
                    src={require("../../../assets/images/ikon-123.svg").default}
                    alt="ikon-123"
                  />{" "}
                  Cégjegyzék száma
                </label>
                <Input
                  id="registrationNumber"
                  mask={[
                    /[1-9]/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  value={values.registrationNumber}
                  handleChange={handleChange}
                  error={errors.registrationNumber}
                />

                <div className="row">
                  <div className="col-md-6 col-xs-6">
                    <Button className="adatCancel" onClick={() => setRegistrationType(undefined)}>
                      Mégse
                    </Button>
                  </div>
                  <div className="col-md-6 col-xs-6">
                    <button disabled={!!Object.keys(errors).length} type="submit">
                      Tovább
                    </button>
                  </div>
                </div>
                <div className="clearBoth" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUpCompany1;
