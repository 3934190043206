import React, { createContext, ComponentType, useState } from 'react';

interface Props {
    selectorType:  "signup" | "login" | "forgottenpwd"
    setSelectorType: (v: "signup" | "login" | "forgottenpwd") => void;
    email: string;
    setEmail: (v: string) => void;
    pwd: string;
    setPwd: (v: string) => void;
}

const LoginContext = createContext<Props>({
  selectorType: 'login', setSelectorType: () => null,
  email: '', setEmail: () => null,
  pwd: '', setPwd: () => null,
});

export const withLoginContext = (Component: ComponentType) => () => {
  const [selectorType, setSelectorType] = useState<"signup" | "login" | "forgottenpwd">('signup');
  const [email, setEmail] = useState('');
  const [pwd, setPwd] = useState('');

  return (
    <LoginContext.Provider value={{ selectorType, setSelectorType, email, setEmail, pwd, setPwd }}>
      <Component />
    </LoginContext.Provider>
  );
};

export default LoginContext;
