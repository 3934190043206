import React, { createContext, ComponentType, useState } from 'react';
import { BillingFrequency } from '../hooks/useFirebaseAuthentication';

interface Props {
    step: 1 | 2 | 3 | 4 | 5 | 6;
    setStep: (v: 1 | 2 | 3 | 4 | 5 | 6) => void;
    startOfRental: Date;
    setStartOfRental: (v: Date) => void;
    billingFrequency: BillingFrequency;
    setBillingFrequency: (v: BillingFrequency) => void;
    selectedGarage?: string;
    setSelectedGarage: (v?: string) => void;
}

const RentContext = createContext<Props>({
  step: 1, setStep: () => null,
  startOfRental: new Date(), setStartOfRental: () => null,
  billingFrequency: 'MONTHLY', setBillingFrequency: () => null,
  selectedGarage: "", setSelectedGarage: () => null,
});

export const withRentContext = (Component: ComponentType) => () => {
  const [step, setStep] = useState<1 | 2 | 3 | 4 | 5 | 6>(1);
  const [startOfRental, setStartOfRental] = useState(new Date());
  const [selectedGarage, setSelectedGarage] = useState<string>();
  const [billingFrequency, setBillingFrequency] = useState<BillingFrequency>('MONTHLY');


  return (
    <RentContext.Provider value={{ selectedGarage, setSelectedGarage, step, setStep, startOfRental, setStartOfRental, billingFrequency, setBillingFrequency }}>
      <Component />
    </RentContext.Provider>
  );
};

export default RentContext;
