import React from 'react';
import MaskedInput, { Mask } from "react-text-mask";

interface Props {
  id: string;
  value: string;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  mask?: Mask;
  error?: string;
  style?: any;
  type?: string;
}

const Input: React.FC<Props> = ({ id, mask, value, handleChange, error, style, type }) => (
    <div>
      {error && (
        <span className="text-red-400" style={{ color: "red", fontSize: 14, position: 'relative', top: -6, left: 8 }}>
          {error}
        </span>
      )}
      {mask ? (
        <MaskedInput
          style={style}
          className="vgInput"
          id={id}
          name={id}
          type={type || "text"}
          onChange={handleChange}
          value={value}
          mask={mask}
          guide={true}
          showMask={true}
        />
      ) : (
        <input
          style={style}
          className="vgInput"
          id={id}
          name={id}
          type={type || "text"}
          onChange={handleChange}
          value={value}
        />
      )}
    </div>
  );

  export default Input;