import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import * as Yup from "yup";
import { collection, serverTimestamp, doc, setDoc } from "firebase/firestore";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/button/button";
import TextBox from "../../../components/textbox/textbox";
import { auth, db } from "../../../config/config";
import SignupPersonContext from "../../../contexts/SignupPersonContext";
import { useFormik } from "formik";
import Input from "../../../components/Input";
import { emailRegex } from "../../../utils/emailRegex";

const schema = Yup.object({
  email: Yup.string()
    .matches(emailRegex, "Érvényes email címet adj meg")
    .required("Kötelező megadni"),
  password: Yup.string().required("Kötelező megadni").min(4, "Túl rövid"),
});

const SignupPersonStep2: React.FC = () => {
  const { state, dispatch } = useContext(SignupPersonContext);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const actionCodeSettings = {
    url: "https://vasgarazs-app.firebaseapp.com",
    handleCodeInApp: true,
  };

  const { values, handleSubmit, handleChange, errors } = useFormik({
    initialValues: {
      email: state.email,
      password: state.password,
    },
    validationSchema: schema,
    onSubmit: ({ email, password }) => {
      setLoading(true);
      dispatch({
        email,
        password,
      });
      createUserWithEmailAndPassword(auth, email, password)
        .then(async (response) => {
          const user = response.user;
          const docid = collection(db, "users");
          await setDoc(doc(docid, user.uid), {
            name: state.personalName,
            email: email,
            address: state.personalAddress,
            id_card: state.personalId,
            contactPhones: state.contactPhones,
            plateNumbers: state.plateNumbers,
            registertypeof: "person",
            role: "user",
            timestamp: serverTimestamp(),
          });
          await sendEmailVerification(user, actionCodeSettings);
          navigate("/confirmation");
        })
        .catch((error) => {
          console.log(error);
        }).finally(() => {
          setLoading(false);
        })
    },
  });

  return (
    <section id="sectionRegisztralj">
      <div className="titlePart">
        <h2>Garázst bérelnél?</h2>
      </div>
      <div id="regisztralj">
        <div id="regisztraljFeherBack" className="regisztracio">
          <h2 className="text-center">Regisztráció</h2>
          <div className="myWindow">
            <div id="formWrapper">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-xs-12 col-md-6">
                    <label>
                      <img
                        src={
                          require("../../../assets/images/ikon-telefon.svg")
                            .default
                        }
                        alt="telefon"
                      />{" "}
                      Kapcsolattartó Tel.:
                    </label>
                    <div className="multipleInputButtonsContainer">
                      <button
                        disabled={state.contactPhones.length <= 1}
                        onClick={() => {
                          const temp = [...state.contactPhones];
                          temp.pop();
                          dispatch({ contactPhones: temp });
                        }}
                      >
                        -
                      </button>
                      <br />
                      <button
                        disabled={state.contactPhones.length >= 3}
                        onClick={() =>
                          dispatch({
                            contactPhones: [...state.contactPhones, ""],
                          })
                        }
                      >
                        +
                      </button>
                    </div>
                    {state.contactPhones.map((number, i) => (
                      <TextBox
                        key={i}
                        value={number}
                        onChange={(e) => {
                          const temp = [...state.contactPhones];
                          temp[i] = e;
                          dispatch({ contactPhones: temp });
                        }}
                        className="vgInput inlineHalfInput"
                        name="PersonalContactPhone"
                        placeholder="+36301111232"
                      />
                    ))}
                  </div>
                  <div className="col-xs-12 col-md-6 posRel">
                    <label>
                      <img
                        src={
                          require("../../../assets/images/ikon-rendszam.svg")
                            .default
                        }
                        alt="ikon-rendszam"
                      />{" "}
                      Rendszám
                    </label>
                    <div className="multipleInputButtonsContainer">
                      <button
                        disabled={state.plateNumbers.length <= 1}
                        onClick={() => {
                          const temp = [...state.plateNumbers];
                          temp.pop();
                          dispatch({ plateNumbers: temp });
                        }}
                      >
                        -
                      </button>
                      <br />
                      <button
                        disabled={state.plateNumbers.length >= 3}
                        onClick={() =>
                          dispatch({
                            plateNumbers: [...state.plateNumbers, ""],
                          })
                        }
                      >
                        +
                      </button>
                    </div>
                    {state.plateNumbers.map((number, i) => (
                      <TextBox
                        key={i}
                        value={number}
                        onChange={(e) => {
                          const temp = [...state.plateNumbers];
                          temp[i] = e;
                          dispatch({ plateNumbers: temp });
                        }}
                        name="PersonalPlateNumber"
                        className="vgInput inlineHalfInput"
                        placeholder="LDX - 234, AA - AA - 123"
                      />
                    ))}
                  </div>
                </div>
                <label>
                  <img
                    src={
                      require("../../../assets/images/ikon-level-fekete.svg")
                        .default
                    }
                    alt="ikon-level-fekete"
                  />{" "}
                  Email cím
                </label>
                <Input
                  id="email"
                  type="email"
                  value={values.email}
                  handleChange={handleChange}
                  error={errors.email}
                />
                <label>
                  <img
                    src={
                      require("../../../assets/images/ikon-kulcs.svg").default
                    }
                    alt="ikon-kulcs"
                  />{" "}
                  Jelszó
                </label>
                <Input
                  id="password"
                  type="password"
                  value={values.password}
                  handleChange={handleChange}
                  error={errors.password}
                />
                <div className="row">
                  <div className="col-md-6 col-xs-6">
                    <Button
                      className="adatCancel"
                      onClick={() => dispatch({ step: 0 })}
                    >
                      Vissza
                    </Button>
                  </div>
                  <div className="col-md-6 col-xs-6">
                    <Button
                      disabled={
                        !!Object.keys(errors).length ||
                        !state.plateNumbers[0]?.length ||
                        !state.contactPhones[0]?.length ||
                        isLoading
                      }
                      className="adatTovabb"
                      type="submit"
                    >
                      Regisztrálás
                    </Button>
                  </div>
                </div>
                <div className="clearBoth" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SignupPersonStep2;
