import React from 'react';
import Footer from '../footer/footer';
import Header from '../header/header';
import '../logout/logout.css';


const Terms = () => {
  return (
    <>
    <Header />
      <div id="pageMain">
        <section id="sectionSzerzodes" className=" aszfWrapper">
          <h2 className="titlePart titlePart2  text-center">
            Általános szerződési feltételek
          </h2>
          <h3 className="subTitle  text-center">Vasgarázs</h3>
          <div className="szerzodesWrapper text-left">
            <ul>
              <li>
                1. Rólunk szóló információk
                <ul>
                  <li>1.1. Ezt a webhelyet a Vasgarázs üzemelteti. </li>
                </ul>
              </li>
              <li>
                2. A weboldal használatának feltételei
                <ul>
                  <li>
                    2.1. Ez az oldal (a rajta hivatkozott dokumentumokkal együtt)
                    meghatározza azokat a felhasználási feltételeket, amelyek alapján
                    Ön igénybe veheti weboldalunkat. Kérjük, olvassa el figyelmesen
                    ezeket a felhasználási feltételeket, mielőtt elkezdi használni az
                    oldalt. Az oldalunk használatával Ön jelzi, hogy elfogadja ezeket
                    a felhasználási feltételeket, amelyek magukban foglalják az
                    oldalunkra vonatkozó adatvédelmi szabályzatot (a továbbiakban:
                    "Adatvédelmi szabályzat"), és hogy elfogadja azok betartását. Ha
                    nem ért egyet ezekkel a felhasználási feltételekkel vagy az
                    Adatvédelmi irányelvekkel, kérjük, tartózkodjon az oldalunk
                    használatától.
                  </li>
                </ul>
              </li>
              <li>
                3. Szellemi jogok
                <ul>
                  <li>
                    3.1. Mi vagyunk az oldalunkhoz és a rajta közzétett anyagokhoz
                    fűződő valamennyi szellemi tulajdonjog tulajdonosa vagy
                    engedélyese. Ezeket a műveket világszerte szerzői jogi törvények
                    és szerződések védik. Minden ilyen jog fenntartva van számunkra és
                    licencadóink számára. Honlapunk látogatójaként az anyag egyetlen
                    példányát töltheti le, kizárólag saját, nem kereskedelmi,
                    magánjellegű megtekintési célokra. Előzetes írásbeli
                    hozzájárulásunk nélkül tilos a másolás vagy terjesztés bármilyen
                    kereskedelmi vagy üzleti célú felhasználás céljából.
                  </li>
                  <li>
                    3.2. Ön semmilyen módon nem módosíthatja az Ön által kinyomtatott
                    vagy letöltött anyagok papíralapú vagy digitális másolatait, és
                    nem használhatja fel az illusztrációkat, fényképeket, video- vagy
                    hangfelvételeket vagy bármilyen grafikát a kísérőszövegtől
                    elkülönítve. Ezen túlmenően, a hozzájárulásunk nélkül nem
                    tartalmazhat linket a honlapunkra, illetve nem jelenítheti meg a
                    honlapunk tartalmát bekeretezve vagy más módon nem tőlünk származó
                    anyaggal körülvéve.
                  </li>
                  <li>
                    3.3. A mi (és az azonosított közreműködők) státuszunkat, mint az
                    oldalunkon található anyagok szerzőit mindig el kell ismerni.
                  </li>
                  <li>
                    3.4. Ha Ön a jelen felhasználási feltételek megsértésével
                    kinyomtatja, lemásolja vagy letölti oldalunk bármely részét, az
                    oldalunk használatára vonatkozó joga azonnal megszűnik, és Ön
                    köteles - a mi választásunk szerint - az Ön által készített
                    anyagok bármely másolatát visszaszolgáltatni vagy megsemmisíteni.
                  </li>
                  <li>
                    3.5. Az oldalunkon használt valamennyi védjegy a megfelelő
                    tulajdonosok tulajdonát képezi.
                  </li>
                </ul>
              </li>
              <li>
                4. Elfogadható használat
                <ul>
                  <li>
                    4.1. Ön kizárólag jogszerű célokra használhatja oldalunkat. Nem
                    használhatja oldalunkat:
                    <ul>
                      <li>
                        4.1.1. Bármilyen módon, amely sérti az alkalmazandó helyi,
                        nemzeti vagy nemzetközi törvényeket vagy előírásokat.
                      </li>
                      <li>
                        4.1.2. Bármilyen módon, amely jogellenes, csalárd, vagy
                        amelynek jogellenes vagy csalárd célja vagy hatása van.
                      </li>
                      <li>
                        4.1.3. Kiskorúak bármilyen módon történő károsítása vagy
                        károsításának kísérlete céljából.
                      </li>
                      <li>
                        4.1.4. Olyan anyagok küldése, tudatos fogadása, feltöltése,
                        letöltése, felhasználása vagy újra felhasználása, amelyek nem
                        felelnek meg az alábbi tartalmi szabványainknak.
                      </li>
                      <li>
                        4.1.5. Bármilyen kéretlen vagy engedély nélküli reklám- vagy
                        promóciós anyag, illetve hasonló kérés (spam) továbbítása,
                        vagy annak elküldésének elősegítése.
                      </li>
                      <li>
                        4.1.6. Tudatosan továbbítani, küldeni vagy feltölteni
                        bármilyen adatot, amely vírusokat, trójai falovakat, férgeket,
                        időzített bombákat, billentyűleütés naplózókat,
                        kémprogramokat, reklámprogramokat vagy bármilyen más káros
                        programot vagy hasonló számítógépes kódot tartalmaz, amelyek
                        célja, hogy hátrányosan befolyásolják bármely számítógépes
                        szoftver vagy hardver működését.
                      </li>
                    </ul>
                  </li>
                  <li>
                    4.2. Ön vállalja továbbá, hogy nem fér hozzá jogosulatlanul, nem
                    zavarja, nem károsítja és nem zavarja meg:
                    <ul>
                      <li>4.2.1. webhelyünk bármely részét;</li>
                      <li>
                        4.2.2. bármely olyan berendezéshez vagy hálózathoz, amelyen az
                        oldalunkat tárolják;
                      </li>
                      <li>
                        4.2.3. az oldalunk szolgáltatása során használt bármely
                        szoftverhez; vagy
                      </li>
                      <li>
                        4.2.4. bármely harmadik fél tulajdonában lévő vagy általa
                        használt berendezést vagy hálózatot, illetve szoftvert.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                5. Üzenet funkciók és tartalomhasználat
                <ul>
                  <li>
                    5.1. Amikor Ön olyan funkciót használ, amely lehetővé teszi, hogy
                    anyagot töltsön fel oldalunkra, vagy kapcsolatba lépjen oldalunk
                    más felhasználóival, be kell tartania ezt a szakaszt. Ön vállalja,
                    hogy minden ilyen hozzájárulás megfelel ezeknek az előírásoknak.
                    Önnek nincs jogi igénye az oldalunkra feltöltött anyagok
                    közzétételére.
                  </li>
                  <li>
                    5.2. Oldalunk lehetőséget kínálhat Önnek üzenetek továbbítására
                    különböző funkciókkal kapcsolatban, beleértve az e-mail-t, az
                    üzenőfalakat és a chat szobákat ("Üzenetfunkciók").
                  </li>
                  <li>
                    5.3. Amennyiben biztosítunk bármilyen Üzenetfunkciót, egyértelmű
                    tájékoztatást nyújtunk Önnek arról, hogy milyen szolgáltatást
                    nyújtunk, hogy moderált-e, és hogy a moderálás milyen formában
                    történik (beleértve azt is, hogy emberi vagy technikai
                    moderálásról van-e szó).
                  </li>
                  <li>
                    5.4. Minden tőlünk telhetőt megteszünk annak érdekében, hogy
                    felmérjük a felhasználókra (különösen a gyermekekre) nézve
                    harmadik felek által jelentett lehetséges kockázatokat, amikor a
                    honlapunkon biztosított Üzenetfunkciókat használják, és minden
                    egyes esetben eldöntjük, hogy e kockázatok fényében megfelelő-e az
                    adott szolgáltatás moderálásának alkalmazása (beleértve azt is,
                    hogy milyen típusú moderálást alkalmazzunk). Nem vagyunk azonban
                    kötelesek felügyelni, ellenőrizni vagy moderálni az oldalunkon
                    biztosított Üzenetfunkciókat, és kifejezetten kizárjuk
                    felelősségünket bármely olyan veszteségért vagy kárért, amely
                    abból ered, hogy egy felhasználó a tartalmi normáinkat megszegve
                    használja az Üzenetfunkciókat, függetlenül attól, hogy a
                    szolgáltatás moderálva van-e vagy sem.
                  </li>
                  <li>
                    5.5. Az Üzenetfunkcióink bármelyikének kiskorúak általi használata
                    a szülő vagy gondviselő beleegyezéséhez kötött. Azt tanácsoljuk
                    azoknak a szülőknek, akik engedélyezik gyermekeiknek az
                    Üzenetfunkcióink használatát, hogy fontos, hogy kommunikáljanak
                    gyermekeikkel az online biztonságukról. Azokat a kiskorúakat, akik
                    bármilyen Üzenetfunkciót használnak, fel kell hívni a figyelmüket
                    a rájuk vonatkozó lehetséges kockázatokra.
                  </li>
                  <li>
                    5.6. Amennyiben moderálunk egy Üzenetfunkciót, általában megadjuk
                    Önnek a moderátorral való kapcsolatfelvétel módját, ha aggodalom
                    vagy nehézség merülne fel.
                  </li>
                  <li>
                    5.7. Az Üzenetfunkciókat felelősségteljes módon kell használnia,
                    és kizárólag Ön felel az Ön által továbbított tartalmakért. Ön nem
                    küldhet olyan üzenetet ("Üzenet") semmilyen Üzenetfunkcióval
                    kapcsolatban, amely:
                    <ul>
                      <li>
                        5.7.1. indokolatlan vagy aránytalanul nagy terhet ró az
                        oldalunk infrastruktúrájára, vagy más módon hátrányosan
                        érinti, korlátozza vagy gátolja bármely más felhasználót az
                        oldalunk használatában és élvezetében;
                      </li>
                      <li>
                        5.7.2. fenyegető, gyalázkodó, rágalmazó, becsületsértő,
                        obszcén, vulgáris, sértő, pornográf, trágár, szexuálisan
                        egyértelmű vagy illetlen;
                      </li>
                      <li>5.7.3. erőszakot népszerűsít;</li>
                      <li>
                        5.7.4. faji, nemi, vallási, nemzetiségi, fogyatékossági,
                        szexuális irányultságon vagy életkoron alapuló
                        megkülönböztetést népszerűsít;
                      </li>
                      <li>
                        5.7.5. olyan magatartást valósít meg vagy ösztönöz, amely
                        bűncselekménynek minősül, polgári jogi felelősségre vonást
                        eredményez vagy más módon sérti a helyi, állami, nemzeti vagy
                        nemzetközi jogot;
                      </li>
                      <li>
                        5.7.6. sérti, plagizál vagy sérti harmadik felek jogait,
                        beleértve, korlátozás nélkül, a szerzői jogot, a védjegyet, a
                        szabadalmat, a magánélethez vagy a nyilvánossághoz való jogot
                        vagy bármely más tulajdonjogot;
                      </li>
                      <li>
                        5.7.7. megszegi a harmadik féllel szemben fennálló jogi
                        kötelezettséget, például szerződéses kötelezettséget vagy
                        bizalmi kötelezettséget;
                      </li>
                      <li>
                        5.7.8. bármilyen kereskedelmi jellegű információt, szoftvert
                        vagy egyéb anyagot tartalmaz;
                      </li>
                      <li>
                        5.7.9. bármilyen reklámot, promóciót vagy kereskedelmi célú
                        felhívást tartalmaz;
                      </li>
                      <li>
                        5.7.10. alkalmas arra, hogy bármely más személyt zaklasson,
                        felzaklasson, zavarba hozzon, riasszon vagy bosszantson;
                      </li>
                      <li>
                        5.7.11. hamis vagy félrevezető eredetmegjelölést vagy
                        tényállításokat tartalmaz, vagy hamis vagy félrevezető
                        eredetmegjelölést, vagy tényállításokat tartalmaz;
                      </li>
                      <li>
                        5.7.12. bármely személy megszemélyesítésére, vagy
                        személyazonosságának vagy bármely személyhez való tartozásának
                        félrevezetésére szolgál (beleértve annak a benyomásnak a
                        keltését, hogy az ilyen üzenet tőlünk származik);
                      </li>
                      <li>
                        5.7.13. az Üzenet funkció tárgya szempontjából irreleváns
                        anyagot tartalmaz; vagy
                      </li>
                      <li>
                        5.7.14. vírust, trójai falovat, férget, időzített bombát,
                        cancelbotot vagy más hasonló káros programozási rutint
                        tartalmaz.
                      </li>
                    </ul>
                  </li>
                  <li>
                    5.8. Saját belátásunk szerint határozzuk meg, hogy az Ön által az
                    oldalunk használata révén történt-e az elfogadható használatra
                    vonatkozó szabályzatunk vagy tartalom használati szabályzatunk
                    megsértése. Ha a jelen szabályzat megsértése történt, az általunk
                    megfelelőnek ítélt intézkedéseket tehetünk (beleértve, de nem
                    kizárólagosan az Ön által továbbított üzenetek vagy
                    felhasználónevek szerkesztését vagy törlését).
                  </li>
                  <li>
                    5.9. A jelen elfogadható felhasználási szabályzat be nem tartása a
                    weboldal használatára vonatkozó feltételeinek lényeges
                    megsértésének minősül, amelyek alapján Ön használhatja
                    weboldalunkat, és az alábbi intézkedések mindegyikének vagy
                    bármelyikének megtételét eredményezheti:
                    <ul>
                      <li>
                        5.9.1. Az oldalunk használatára vonatkozó jogának azonnali,
                        ideiglenes vagy végleges visszavonása.
                      </li>
                      <li>
                        5.9.2. Az Ön által az oldalunkra feltöltött bármely bejegyzés
                        vagy anyag azonnali, ideiglenes vagy végleges eltávolítása.
                      </li>
                      <li>5.9.3. Figyelmeztetés kiadása Önnek. </li>
                      <li>
                        5.9.4. Jogi eljárást indítunk Ön ellen a jogsértésből eredő
                        összes költség (beleértve, de nem kizárólagosan, az ésszerű
                        adminisztratív és jogi költségeket) kártérítési alapon történő
                        megtérítése érdekében.
                      </li>
                      <li>5.9.5. További jogi lépések megtétele Ön ellen. </li>
                      <li>
                        5.9.6. Azoknak az információknak a bűnüldöző hatóságoknak való
                        átadása, amelyeket ésszerűen szükségesnek tartunk.
                      </li>
                    </ul>
                  </li>
                  <li>
                    5.10. Kizárjuk a felelősséget a jelen elfogadható felhasználási
                    szabályzat megsértése miatt tett intézkedésekért. A jelen
                    szabályzatban leírt válaszlépések nem korlátozottak, és bármilyen
                    más, általunk ésszerűen megfelelőnek ítélt intézkedést is
                    megtehetünk.
                  </li>
                </ul>
              </li>
              <li>
                6. GYERMEKEK ÉS FIATALOK
                <ul>
                  <li>
                    6.1. Oldalunk elsősorban 18 év feletti felhasználóknak szól. A 18
                    év alattiak számára azonban nincs akadálya annak, hogy
                    hozzáférjenek oldalunkhoz, vagy felhasználóként regisztráljanak. A
                    18 év alatti felhasználók csak szülő vagy gondviselő engedélyével
                    használhatják oldalunkat. Az oldalunkat használó 18 év alattiak
                    szüleinek vagy gyámjainak figyelmesen át kell tekinteniük ezeket a
                    felhasználási feltételeket.
                  </li>
                </ul>
              </li>
              <li>
                7. Tájékoztatás és elérhetőség
                <ul>
                  <li>
                    7.1. Bár mindent megteszünk annak érdekében, hogy az oldalunkon
                    található információk pontosak és teljesek legyenek, azok egy
                    részét harmadik felek bocsátják rendelkezésünkre, és nem áll
                    módunkban ellenőrizni azok pontosságát vagy teljességét.
                    Javasoljuk, hogy ellenőrizze az információk pontosságát, mielőtt
                    azokra hagyatkozna. Továbbá, az internet természetéből adódóan a
                    szolgáltatásban bármikor előfordulhatnak hibák, megszakítások és
                    késések. Ennek megfelelően oldalunkat "úgy, ahogy van", mindenféle
                    szavatosság nélkül nyújtjuk, és nem vállalunk felelősséget az
                    információkban található pontatlanságokból vagy hiányosságokból,
                    illetve az elérhetőség megszakításából adódóan.
                  </li>
                </ul>
              </li>
              <li>
                8. Az oldalunkról induló linkek
                <ul>
                  <li>
                    8.1. Amennyiben oldalunk harmadik felek által biztosított más
                    oldalakra és forrásokra mutató linkeket tartalmaz, nem
                    rendelkezünk ellenőrzéssel ezen oldalak vagy források tartalma
                    felett, és nem vállalunk felelősséget azokért, illetve az azok
                    használatából eredő esetleges veszteségekért vagy károkért.
                  </li>
                </ul>
              </li>
              <li>
                9. Versenyek és szavazások
                <ul>
                  <li>
                    9.1. Időről időre mi (vagy kiválasztott harmadik felek)
                    szavazásokat, versenyeket, promóciókat vagy egyéb ajánlatokat
                    helyezhetünk el oldalunkon. Minden ilyen ajánlatra a saját
                    kifejezett feltételei vonatkoznak, és előfordulhat, hogy nem
                    minden joghatóságban érhető el.
                  </li>
                </ul>
              </li>
              <li>
                10. Mobil felhasználási feltételek 10.1. Oldalunk mobilon keresztül
                történő elérésekor a szokásos hálózati díjakat kell alkalmazni. Az Ön
                mobiltelefonjának WAP-kompatibilisnek kell lennie. A szerződésének
                megfelelően további WAP- és GPRS-díjakat számíthat fel a hálózat
                üzemeltetője.
              </li>
              <li>
                11. Változás
                <ul>
                  <li>
                    11.1. A jelen felhasználási feltételeket bármikor
                    felülvizsgálhatjuk az oldal módosításával. Elvárjuk, hogy időről
                    időre ellenőrizze ezt az oldalt, hogy tudomásul vegye az általunk
                    eszközölt módosításokat, mivel azok kötelező érvényűek Önre nézve.
                    A jelen felhasználási feltételek egyes rendelkezéseit az
                    oldalunkon máshol közzétett rendelkezések vagy közlemények is
                    felválthatják.
                  </li>
                </ul>
              </li>
              <li>
                12. Szabadság
                <ul>
                  <li>
                    12.1 Ön beleegyezik, hogy kártalanít minket és kapcsolt
                    vállalkozásainkat, valamint a megfelelő igazgatóinkat,
                    tisztviselőinket, alkalmazottainkat és ügynökeinket, valamint
                    licencadóikat és beszállítóikat minden olyan követelés, kereset,
                    per vagy eljárás, valamint minden olyan veszteség, felelősség,
                    kár, költség és kiadás (beleértve az észszerű ügyvédi költségeket)
                    alól, amely a következőkből ered:
                    <ul>
                      <li>
                        12.1.1. bármely, az Ön által az oldalunk használatával
                        kapcsolatban tett hamis nyilatkozat, cselekedet vagy
                        mulasztás;
                      </li>
                      <li>
                        12.1.2. a jelen felhasználási feltételek Ön általi be nem
                        tartása; és
                      </li>
                      <li>
                        12.1.3. harmadik felek által benyújtott követelések, amelyek
                        az Ön által az oldalunkhoz való hozzáférésből vagy annak
                        használatából erednek vagy azzal kapcsolatosak, beleértve, de
                        nem kizárólagosan az Üzenetfunkciókat vagy az Ön által az
                        oldalunkon elérhetővé tett egyéb információkat.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                13. Felelősségvállalás
                <ul>
                  <li>
                    13. 1. Webhelyünk minden, a termékkínálatunkon kívüli aspektusa
                    tekintetében (és a jelen 13. szakasz egyéb rendelkezéseire is
                    figyelemmel), Mi, bármely más fél (függetlenül attól, hogy részt
                    vesz-e a weboldalunk létrehozásában, előállításában,
                    karbantartásában vagy kiszállításában), valamint bármely
                    csoportunkhoz tartozó vállalat, valamint a tisztségviselők,
                    igazgatók, alkalmazottak, részvényesei vagy ügynökei, kizárunk
                    minden felelősséget és felelősséget bármilyen összegű vagy jellegű
                    veszteségért vagy kárért, amely Önt vagy egy harmadik felet érhet
                    (beleértve, de nem kizárólagosan, bármilyen közvetlen, közvetett,
                    büntető jellegű vagy következményes veszteséget vagy kárt, vagy
                    bármilyen jövedelem, nyereség, jó hírnév, adat, szerződés,
                    pénzhasználat elvesztését, vagy az üzletmenet megszakadásából
                    eredő vagy azzal bármilyen módon összefüggő veszteség vagy kár,
                    akár kártérítési (beleértve, korlátozás nélkül, a gondatlanságot),
                    akár szerződés vagy más okból) a honlapunkkal bármilyen módon vagy
                    az Üzenetfunkciókkal kapcsolatban, vagy a honlapunk használatával,
                    használhatatlanságával vagy használatának eredményeivel
                    kapcsolatban, az oldalunkhoz kapcsolódó bármely weboldal vagy az
                    ilyen weboldalakon található anyagok, beleértve, de nem
                    kizárólagosan, az Ön számítógépes berendezéseit, szoftvereit,
                    adatait vagy egyéb tulajdonát megfertőző vírusok okozta
                    veszteségeket vagy károkat, amelyek az oldalunkhoz való
                    hozzáférése, használata vagy böngészése, illetve az oldalunkról
                    vagy az oldalunkhoz kapcsolódó bármely weboldalról származó
                    anyagok letöltése miatt következnek be
                  </li>
                  <li>
                    13.2. Ha Ön úgy dönt, hogy az Üzenetfunkciókon keresztül vagy
                    bármilyen más módon közzéteszi tartózkodási helyét, tervezett
                    tartózkodási helyét vagy bármilyen más ilyen személyes információt
                    az oldalunkon, elfogadja, hogy ez teljes mértékben az Ön saját
                    kockázatára történik, és nem vagyunk felelősek semmilyen
                    veszteségért vagy kárért, amely a tartózkodási helyére vagy
                    tervezett tartózkodási helyére vonatkozó információk megosztása
                    következtében következik be.
                  </li>
                  <li>
                    13.3. Ez a szakasz semmilyen módon nem korlátozza felelősségünket:
                    <ul>
                      <li> 13.3.1. súlyos gondatlanság vagy szándékosság esetén;</li>
                      <li>
                        13.3.2. a mi gondatlanságunk által okozott halálesetért vagy
                        személyi sérülésért;
                      </li>
                      <li>13.3.3. csalásért vagy csalárd félrevezetésért; vagy</li>
                      <li>
                        13.3.4. bármely olyan dologért, amelyért jogellenes lenne
                        kizárni vagy megkísérelni kizárni a felelősségünket.
                      </li>
                    </ul>
                  </li>
                  <li>
                    13.4. Amennyiben Ön harmadik féltől származó terméket vagy
                    szolgáltatást vásárol az oldalunkon keresztül, az eladó egyéni
                    felelőssége az eladó általános szerződési feltételeiben kerül
                    meghatározásra.
                  </li>
                </ul>
              </li>
              <li>
                14. Joghatóság és alkalmazandó jog
                <ul>
                  <li>
                    A jelen felhasználási feltételekre kizárólag Anglia és Wales
                    törvényei vonatkoznak, és a törvény által megengedett mértékben a
                    törvények szerint kell értelmezni. Az Ön országának kötelező
                    fogyasztóvédelmi jogszabályai nem érintik.
                  </li>
                </ul>
              </li>
              <li>
                15. Hatályosság
                <ul>
                  <li>
                    Ha a jelen felhasználási feltételek valamely rendelkezése
                    jogellenesnek és/vagy végrehajthatatlannak minősül, a
                    felhasználási feltételek többi részének érvényessége nem sérül. Ha
                    bármely jogellenes vagy végrehajthatatlan rendelkezés akkor lenne
                    jogszerű vagy végrehajtható, ha egy részét törölnék, az adott rész
                    törlendőnek tekintendő, a rendelkezés többi része pedig továbbra
                    is érvényben marad.
                  </li>
                </ul>
              </li>
              <li>
                17. Kapcsolatfelvétel
                <ul>
                  <li>
                    E-mail cím: vasgarazs@gmail.com Telefonszám: +36/306897726
                  </li>
                </ul>
              </li>
            </ul>
            <div className="text-right">Vasgarázs</div>
          </div>
        </section>
      </div>  
    <Footer />
    </>  
  );
};

export default Terms;