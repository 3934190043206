import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendPasswordResetEmail } from 'firebase/auth';
import Button from '../../../components/button/button';
import TextBox from '../../../components/textbox/textbox';
import { auth } from '../../../config/config';
import LoginContext from '../../../contexts/LoginContext';

const ForgottenPwd: React.FC = () => {
    const { email, setEmail, setSelectorType } = useContext(LoginContext);
    const navigate = useNavigate();

        const ForgottenPwd = async () => {
            sendPasswordResetEmail(auth, email)
                .then((response) => {
                    alert("Jelszó-visszaállítási link elküldve!");
                    navigate('/');
                })
                .catch((error) => {
                    console.log(error);
                });
        };
        return (
        <>
            <section id="sectionRegisztralj">
                <div id="regisztralj">
                    <div id="regisztraljFeherBack">
                        <h3>Elfelejtette a jelszavát?</h3>
                        <p>Adja meg az e-mail címét <br></br>az új jelszó beállításához. </p>
                        <div className="nagyGombWrapper">
                            <div className="myWindow">
                                <div className="formWrapper">
                                    <label>
                                    <img src={require('../../../assets/images/ikon-level-fekete.svg').default} alt='ikon-level-fekete' /> Email cím
                                    </label>
                                    <TextBox value={email} onChange={setEmail} type="email" className="vgInput" name="email" />
                                    <div className="row">
                                        <div className="col-md-6 col-xs-6">
                                            <Button className="adatCancel" onClick={() => setSelectorType('login')}>Mégse</Button>
                                        </div>
                                        <div className="col-md-6 col-xs-6">
                                            <Button className="adatTovabb" onClick={() => ForgottenPwd()}>Új Jelszó küldése</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
        );
    }

export default ForgottenPwd